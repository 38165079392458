import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { FaArrowLeft } from "react-icons/fa";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  increment,
  runTransaction,
} from "firebase/firestore";
import { db } from "../firebase";
import { UserAuth } from "../context/AuthContext";
import LoadingScreen from "./LoadingScreen";

const SupplyRequest = ({ type, quantity, setQuantity }) => {
  const handleButtonClick = (value) => {
    setQuantity(value);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: 20,
        maxWidth: 300,
      }}
    >
      <div style={{ textAlign: "center", marginRight: "10px", flex: 1 }}>
        <img
          src={require(`../assets/PHP${type}.png`)}
          alt={`PHP${type}`}
          style={{
            width: "100%",
            objectFit: "contain",
            borderRadius: 10,
          }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <button
          onClick={() => handleButtonClick(0)}
          style={{
            flex: 1,
            borderRadius: 5,
            border: "none",
            marginTop: 1,
            marginBottom: 1,
            backgroundColor: quantity === 0 ? "#00FFAB" : null,
          }}
        >
          <p style={{ fontSize: 18 }}>0</p>
        </button>
        <button
          onClick={() => handleButtonClick(50)}
          style={{
            flex: 1,
            borderRadius: 5,
            border: "none",
            marginTop: 1,
            marginBottom: 1,
            backgroundColor: quantity === 50 ? "#00FFAB" : null,
          }}
        >
          <p style={{ fontSize: 18 }}>50</p>
        </button>
        <button
          onClick={() => handleButtonClick(100)}
          style={{
            flex: 1,
            borderRadius: 5,
            border: "none",
            marginTop: 1,
            marginBottom: 1,
            backgroundColor: quantity === 100 ? "#00FFAB" : null,
          }}
        >
          <p style={{ fontSize: 18 }}>100</p>
        </button>
        <button
          onClick={() => handleButtonClick(200)}
          style={{
            flex: 1,
            borderRadius: 5,
            border: "none",
            marginTop: 1,
            marginBottom: 1,
            backgroundColor: quantity === 200 ? "#00FFAB" : null,
          }}
        >
          <p style={{ fontSize: 18 }}>200</p>
        </button>
      </div>
    </div>
  );
};

const RequestSupply = () => {
  const [PHP5, setPHP5] = useState(0);
  const [PHP10, setPHP10] = useState(0);
  const [PHP15, setPHP15] = useState(0);
  const [requestRemit, setRequestRemit] = useState(false);
  const navigate = useNavigate();
  const { accessCode } = UserAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestButtonDisabled, setIsRequestButtonDisabled] = useState(true);

  const handleCheckboxChange = (event) => {
    if (event.target.name === "requestRemit") {
      setRequestRemit(!requestRemit);
    }
  };

  useEffect(() => {
    if (PHP5 > 0 || PHP10 > 0 || PHP15 > 0) {
      setIsRequestButtonDisabled(false);
    } else {
      setIsRequestButtonDisabled(true);
    }
  }, [PHP5, PHP10, PHP15]);

  async function requestSupply() {
    try {
      await addDoc(collection(db, "SupplyRequests"), {
        accessCode: accessCode,
        approved: false,
        PHP5: PHP5,
        PHP10: PHP10,
        PHP15: PHP15,
        requestRemit: requestRemit,
        timestamp: new Date(),
        status: "pending",
        deliveryDate: null,
      }).then(() => {
        const notificationRef = doc(db, "Notifications", "SupplyRequests");
        updateDoc(notificationRef, { [accessCode]: increment(1) });
      });
    } catch (e) {
      console.error("Error adding document: ", e);
      // Handle any errors that may occur during the request
    }
  }

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <div
        style={{
          backgroundColor: "#083780",
          color: "white",
          margin: "0 auto",
          padding: "20px",
          overflowX: "hidden",
          width: 300,
        }}
      >
        <button
          style={{
            border: "none",
            background: "none",
          }}
          onClick={() => {
            navigate("../dashboard", { replace: true });
          }}
        >
          <FaArrowLeft size={20} color={"white"} style={{ marginBottom: 20 }} />
        </button>
        <h2
          style={{
            margin: 0,
            marginBottom: 20,
            color: "#FFDD83",
            textAlign: "center",
          }}
        >
          Request Supply
        </h2>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <SupplyRequest type={5} quantity={PHP5} setQuantity={setPHP5} />
          <SupplyRequest type={10} quantity={PHP10} setQuantity={setPHP10} />
          <SupplyRequest type={15} quantity={PHP15} setQuantity={setPHP15} />
        </div>

        {/* <div style={{ display: "flex", justifyContent: "center" }}>
          <label>
            <input
              type="checkbox"
              name="requestRemit"
              onChange={handleCheckboxChange}
              checked={requestRemit}
            />
            Isasabay liwat pagremit
          </label>
        </div> */}
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <button
            disabled={isRequestButtonDisabled}
            style={{
              flex: 1,
              padding: 10,
              maxWidth: 300,
              backgroundColor: isRequestButtonDisabled ? "#ccc" : "#EB455F",
              borderRadius: 3,
              color: "#FCFFE7",
              fontWeight: "bold",
              border: "none",
            }}
            onClick={async () => {
              setIsLoading(true);
              await requestSupply();
              setIsLoading(false);
              navigate("../viewsupplyrequests", { replace: true });
            }}
          >
            <p>Request</p>
          </button>
        </div>
      </div>
    );
  }
};

export default RequestSupply;
