import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import GoBack from "../components/GoBack";
import LoadingScreen from "./LoadingScreen";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  increment,
  runTransaction,
  writeBatch,
} from "firebase/firestore";
import { db } from "../firebase";

const ConfirmRemit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stationAccessCode = location.state.stationAccessCode;
  const stationDATA = location.state.stationDATA;
  const supplyPHP5 = stationDATA.supply.PHP5;
  const supplyPHP10 = stationDATA.supply.PHP10;
  const supplyPHP15 = stationDATA.supply.PHP15;
  const remainingPHP5 = parseInt(location.state.remainingPHP5);
  const remainingPHP10 = parseInt(location.state.remainingPHP10);
  const remainingPHP15 = parseInt(location.state.remainingPHP15);
  const includeElectricity = location.state.includeElectricity;
  const stationShare = stationDATA.share;
  const myShare = 1 - stationShare;
  const [isLoading, setIsLoading] = useState(false);

  const countVoucherPrice = (targetVoucherPrice) => {
    let count = 0;

    stationDATA.errorCodes.forEach((errorCode) => {
      const { voucherPrice } = errorCode;

      // Check if the current voucherPrice matches the targetVoucherPrice
      if (voucherPrice === targetVoucherPrice) {
        count++;
      }
    });

    return count;
  };

  const errorCodePHP5 = countVoucherPrice(5);
  const errorCodePHP10 = countVoucherPrice(10);
  const errorCodePHP15 = countVoucherPrice(15);

  const EachMiniVoucher = ({ type, quantity }) => {
    return (
      <div
        style={{
          textAlign: "center",
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          margin: 5,
        }}
      >
        <img
          src={require(`../assets/PHP${type}.png`)}
          alt={`PHP${type}`}
          style={{
            width: "50%",
            objectFit: "contain",
            borderRadius: 10,
          }}
        />
        <p
          style={{
            fontWeight: "bold",
            fontSize: 18,
            color: "white",
            marginLeft: 5,
          }}
        >
          {quantity}
        </p>
      </div>
    );
  };

  async function processRemit() {
    // supply reduction
    // error codes set to 0
  }

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <div
        style={{
          backgroundColor: "#083780",
          color: "white",
          margin: "0 auto",
          padding: "20px",
          overflowX: "hidden",
          width: 300,
        }}
      >
        <GoBack
          onClickFunction={() => {
            navigate("/viewstation", {
              replace: true,
              state: stationAccessCode,
            });
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <h2 style={{ margin: 0 }}>Review Details</h2>
          <p style={{ fontSize: 12, marginBottom: 30, marginTop: 10 }}>
            Please review the details before confirming the remit
          </p>
          {/* supply */}
          <div
            style={{
              marginBottom: 20,
              border: "1px solid #FFDD83",
              padding: 10,
              borderRadius: 10,
              width: "100%",
            }}
          >
            <p
              style={{
                fontSize: 18,
                marginBottom: 20,
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Supply
            </p>
            <div style={{ flex: 1, display: "flex" }}>
              <EachMiniVoucher type={5} quantity={stationDATA.supply.PHP5} />
              <EachMiniVoucher type={10} quantity={stationDATA.supply.PHP10} />
              <EachMiniVoucher type={15} quantity={stationDATA.supply.PHP15} />
            </div>
          </div>
          {/* remaining */}
          <div
            style={{
              marginBottom: 30,
              border: "1px solid #FFDD83",
              padding: 10,
              borderRadius: 10,
              width: "100%",
            }}
          >
            <p
              style={{
                fontSize: 18,
                marginBottom: 20,
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Remaining
            </p>
            <div style={{ flex: 1, display: "flex" }}>
              <EachMiniVoucher type={5} quantity={remainingPHP5} />
              <EachMiniVoucher type={10} quantity={remainingPHP10} />
              <EachMiniVoucher type={15} quantity={remainingPHP15} />
            </div>
          </div>
          {/* error codes */}
          <div
            style={{
              marginBottom: 30,
              border: "1px solid #FFDD83",
              padding: 10,
              borderRadius: 10,
              width: "100%",
            }}
          >
            <p
              style={{
                fontSize: 18,
                marginBottom: 20,
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Error Codes
            </p>
            {stationDATA.errorCodes.length > 0 ? (
              <div style={{ flex: 1, display: "flex" }}>
                <EachMiniVoucher type={5} quantity={errorCodePHP5} />
                <EachMiniVoucher type={10} quantity={errorCodePHP10} />
                <EachMiniVoucher type={15} quantity={errorCodePHP15} />
              </div>
            ) : (
              <p
                style={{
                  fontSize: 13,
                  fontWeight: "400",
                  color: "#ccc",
                  textAlign: "center",
                }}
              >
                No error codes
              </p>
            )}
          </div>
          {/* electricity benefit */}
          <div
            style={{
              marginBottom: 30,
              border: "1px solid #FFDD83",
              padding: 10,
              borderRadius: 10,
              width: "100%",
            }}
          >
            <p
              style={{
                fontSize: 18,
                marginBottom: 20,
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Station Electricity Share
            </p>
            {includeElectricity ? (
              <p
                style={{ textAlign: "center", fontSize: 20, fontWeight: "600" }}
              >
                PHP {stationDATA.electricity}.00
              </p>
            ) : (
              <p
                style={{
                  fontSize: 13,
                  color: "#ccc",
                  textAlign: "center",
                  fontWeight: "400",
                }}
              >
                Not yet
              </p>
            )}
          </div>
          {/* profit sharing */}
          <div
            style={{
              marginBottom: 30,
              border: "1px solid #FFDD83",
              padding: 10,
              borderRadius: 10,
              width: "100%",
            }}
          >
            <div>
              <p
                style={{
                  fontSize: 18,
                  marginBottom: 20,
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Profit sharing
              </p>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <p style={{ textAlign: "center" }}>My Share</p>
                  <p
                    style={{
                      fontSize: 25,
                      fontWeight: "600",
                      textAlign: "center",
                      marginTop: 10,
                      color: "#FFDD83",
                    }}
                  >
                    {myShare * 100 + "%"}
                  </p>
                </div>
                <div style={{ flex: 1 }}>
                  <p style={{ textAlign: "center" }}>Station's Share</p>
                  <p
                    style={{
                      fontSize: 25,
                      fontWeight: "600",
                      textAlign: "center",
                      marginTop: 10,
                      color: "#FFDD83",
                    }}
                  >
                    {stationShare * 100 + "%"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            style={{
              width: "100%",
              backgroundColor: "#EB455F",
              maxWidth: 300,
              border: "none",
              borderRadius: 3,
              color: "#FCFFE7",
              fontWeight: "bold",
            }}
            onClick={async () => {
              try {
                setIsLoading(true);
                const finalSupplyPHP5 =
                  parseInt(supplyPHP5) - parseInt(errorCodePHP5);
                const finalSupplyPHP10 =
                  parseInt(supplyPHP10) - parseInt(errorCodePHP10);
                const finalSupplyPHP15 =
                  parseInt(supplyPHP15) - parseInt(errorCodePHP15);

                const soldPHP5 = finalSupplyPHP5 - remainingPHP5;
                const soldPHP10 = finalSupplyPHP10 - remainingPHP10;
                const soldPHP15 = finalSupplyPHP15 - remainingPHP15;
                const salesPHP5 = soldPHP5 * 5;
                const salesPHP10 = soldPHP10 * 10;
                const salesPHP15 = soldPHP15 * 15;
                const totalSales =
                  parseInt(salesPHP5) +
                  parseInt(salesPHP10) +
                  parseInt(salesPHP15);
                const myProfitShare = includeElectricity
                  ? totalSales * myShare - stationDATA.electricity
                  : totalSales * myShare;
                const stationProfitShare = includeElectricity
                  ? totalSales * stationShare +
                    parseInt(stationDATA.electricity)
                  : totalSales * stationShare;

                const batch = writeBatch(db);
                // set doc for remit
                const remitCollectionRef = collection(db, "Remit");
                const docRef = doc(remitCollectionRef);

                batch.set(docRef, {
                  remitDATA: {
                    includeElectricity: includeElectricity,
                    profit: {
                      total: totalSales,
                      myShare: myProfitShare,
                      stationShare: stationProfitShare,
                    },
                    supply: {
                      old: {
                        PHP5: supplyPHP5,
                        PHP10: supplyPHP10,
                        PHP15: supplyPHP15,
                      },
                      new: {
                        PHP5: remainingPHP5,
                        PHP10: remainingPHP10,
                        PHP15: remainingPHP15,
                      },
                    },
                    voucher: {
                      error: {
                        PHP5: errorCodePHP5,
                        PHP10: errorCodePHP10,
                        PHP15: errorCodePHP15,
                      },
                      sold: {
                        PHP5: soldPHP5,
                        PHP10: soldPHP10,
                        PHP15: soldPHP15,
                      },
                      remaining: {
                        PHP5: remainingPHP5,
                        PHP10: remainingPHP10,
                        PHP15: remainingPHP15,
                      },
                    },
                  },
                  stationDATA: {
                    accessCode: stationAccessCode,
                    electricityBenefit: stationDATA.electricity,
                    percentageShare: stationShare,
                  },
                  timestamp: serverTimestamp(),
                });

                // update the station supply
                const stationRef = doc(db, "AccessCodes", stationAccessCode);
                batch.update(stationRef, {
                  supply: {
                    PHP5: remainingPHP5,
                    PHP10: remainingPHP10,
                    PHP15: remainingPHP15,
                  },
                  errorCodes: [],
                });
                await batch.commit();
                setIsLoading(false);
                navigate("/doneremit", {
                  state: {
                    remitDATA: {
                      includeElectricity: includeElectricity,
                      profit: {
                        total: totalSales,
                        myShare: myProfitShare,
                        stationShare: stationProfitShare,
                      },
                      supply: {
                        old: {
                          PHP5: supplyPHP5,
                          PHP10: supplyPHP10,
                          PHP15: supplyPHP15,
                        },
                        new: {
                          PHP5: remainingPHP5,
                          PHP10: remainingPHP10,
                          PHP15: remainingPHP15,
                        },
                      },
                      voucher: {
                        error: {
                          PHP5: errorCodePHP5,
                          PHP10: errorCodePHP10,
                          PHP15: errorCodePHP15,
                        },
                        sold: {
                          PHP5: soldPHP5,
                          PHP10: soldPHP10,
                          PHP15: soldPHP15,
                        },
                        remaining: {
                          PHP5: remainingPHP5,
                          PHP10: remainingPHP10,
                          PHP15: remainingPHP15,
                        },
                      },
                      timestamp: new Date(),
                    },
                    stationDATA: {
                      accessCode: stationAccessCode,
                      electricityBenefit: stationDATA.electricity,
                      percentageShare: stationShare,
                    },
                    remitFailed: false,
                  },
                  replace: true,
                });
              } catch (error) {
                console.log(error.message);
                navigate("/doneremit", {
                  state: {
                    stationAccessCode: stationAccessCode,
                    remitDATA: null,
                    remitFailed: true,
                  },
                  replace: true,
                });
              }
            }}
          >
            <p style={{ margin: 10 }}>Remit</p>
          </button>
        </div>
      </div>
    );
  }
};

export default ConfirmRemit;
