import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import GoBack from "../components/GoBack";
import { db } from "../firebase";
import LoadingScreen from "./LoadingScreen";
import { collection, getDocs, where, query, orderBy } from "firebase/firestore";
import { RiHomeWifiFill } from "react-icons/ri";

const ViewSubAdminRemitHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const remitHistory = location.state.remitHistory;
  const subAdminName = location.state.subAdminName;

  const [isLoading, setIsLoading] = useState(false);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const formatDate2 = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const formatPrice = (amount) => {
    // Function to format number to Philippine peso currency
    const formatToPhilippineCurrency = (value) => {
      // Convert value to a string and split it into whole and decimal parts
      const [wholePart, decimalPart] = value.toString().split(".");

      // Format whole part with comma separator every 3 digits
      const formattedWholePart = wholePart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      // If there's a decimal part, add it with 2 decimal places; otherwise, add .00
      const formattedDecimalPart = decimalPart
        ? `.${decimalPart.padEnd(2, "0")}`
        : ".00";

      // Return the formatted currency string with the Philippine peso symbol
      return `₱${formattedWholePart}${formattedDecimalPart}`;
    };

    // Call the formatting function and return the result
    return formatToPhilippineCurrency(amount);
  };

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <div
        style={{
          backgroundColor: "#083780",
          color: "white",
          margin: "0 auto",
          padding: "20px",
          overflowX: "hidden",
          width: 300,
        }}
      >
        <GoBack
          onClickFunction={() => {
            navigate("../dashboard", {
              replace: true,
            });
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2 style={{ margin: 0, marginBottom: 10 }}>Remit History</h2>
          {remitHistory.length > 0 ? (
            remitHistory.map((remit) => {
              return (
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    backgroundColor: "#FFDD83",
                    color: "black",
                    width: "90%",
                    display: "flex",
                    flexDirection: "column",
                    padding: 10,
                    borderRadius: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginBottom: 20,
                    }}
                  >
                    <p style={{ fontSize: 12, fontWeight: "bold" }}>
                      {formatDate(remit.timestamp)}
                    </p>
                    <p
                      style={{
                        fontSize: 25,
                        fontWeight: "bold",
                        marginTop: 10,
                      }}
                    >
                      {formatPrice(remit.totalRemittance)}
                    </p>
                    <p style={{ fontSize: 13 }}>Total Remittance</p>
                  </div>
                  <p
                    style={{
                      fontSize: 16,
                      textAlign: "center",
                      marginBottom: 10,
                    }}
                  >
                    Stations
                  </p>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <p style={{ fontSize: 13, flex: 1, fontWeight: "bold" }}>
                      Name
                    </p>
                    <p style={{ fontSize: 13, flex: 1, fontWeight: "bold" }}>
                      Remittance
                    </p>
                    <p style={{ fontSize: 13, flex: 1, fontWeight: "bold" }}>
                      Share
                    </p>
                  </div>
                  {remit.stationsDATA.map((station) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <p style={{ fontSize: 13, flex: 1 }}>{station.name}</p>
                        <p style={{ fontSize: 13, flex: 1 }}>
                          {formatPrice(station.remittance)}
                        </p>
                        <p style={{ fontSize: 13, flex: 1 }}>
                          {formatPrice(station.shareAmount)}
                        </p>
                      </div>
                    );
                  })}
                  <p
                    style={{
                      fontSize: 16,
                      textAlign: "center",
                      marginTop: 20,
                      marginBottom: 10,
                    }}
                  >
                    Admins
                  </p>
                  <div style={{ display: "flex" }}>
                    <p style={{ fontSize: 13, flex: 1, fontWeight: "bold" }}>
                      Name
                    </p>
                    <p
                      style={{ fontSize: 13, flex: 1, fontWeight: "bold" }}
                    ></p>
                    <p style={{ fontSize: 13, flex: 1, fontWeight: "bold" }}>
                      Share
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <p style={{ fontSize: 13, flex: 1 }}>{subAdminName}</p>
                    <p style={{ flex: 1 }}> </p>
                    <p style={{ fontSize: 13, flex: 1 }}>
                      {formatPrice(remit.share.subAdmin.amount)}
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <p style={{ fontSize: 13, flex: 1 }}>Adnel</p>
                    <p style={{ flex: 1 }}> </p>
                    <p style={{ fontSize: 13, flex: 1 }}>
                      {formatPrice(remit.share.adnel.amount)}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <p style={{ fontSize: 13, color: "lightgray", marginTop: 10 }}>
              Empty record
            </p>
          )}
        </div>
      </div>
    );
  }
};

export default ViewSubAdminRemitHistory;
