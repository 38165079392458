import React from "react";
import { FaArrowLeft } from "react-icons/fa";

const GoBack = (props) => {
  return (
    <button
      style={{
        border: "none",
        background: "none",
        marginBottom: 20,
      }}
      onClick={props.onClickFunction}
    >
      <FaArrowLeft size={20} color={"white"} />
    </button>
  );
};

export default GoBack;
