import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import GoBack from "../components/GoBack";
import { db } from "../firebase";
import LoadingScreen from "./LoadingScreen";
import { collection, getDocs, where, query, orderBy } from "firebase/firestore";

const ViewRemitHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessCode = location.state.accessCode;
  const userRole = location.state.userRole;
  const [isLoading, setIsLoading] = useState(false);
  const [remitHistoryDATA, setRemitHistoryDATA] = useState([]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    if (accessCode) {
      const fetchRemitDATA = async () => {
        try {
          setIsLoading(true);
          const remitCollectionRef = collection(db, "Remit");
          const querySnapshot = await getDocs(
            query(
              remitCollectionRef,
              where("stationDATA.accessCode", "==", accessCode),
              orderBy("timestamp", "desc")
            )
          );

          if (querySnapshot.empty) {
            setRemitHistoryDATA(null);
          } else {
            setRemitHistoryDATA(querySnapshot.docs);
          }
          setIsLoading(false);
        } catch (error) {
          console.log(error.message);
        }
      };

      fetchRemitDATA();
    }
  }, [accessCode]);

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <div
        style={{
          backgroundColor: "#083780",
          color: "white",
          margin: "0 auto",
          padding: "20px",
          overflowX: "hidden",
          width: 300,
        }}
      >
        <GoBack
          onClickFunction={() => {
            if (userRole === "admin") {
              navigate("/viewstation", {
                replace: true,
                state: accessCode,
              });
            } else {
              navigate("../dashboard", {
                replace: true,
              });
            }
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2 style={{ margin: 0, marginBottom: 10 }}>Remit History</h2>
          {remitHistoryDATA ? (
            remitHistoryDATA.map((data) => {
              const remitDATA = data.data().remitDATA;
              const timestamp = data.data().timestamp;

              return (
                <div
                  onClick={() => {
                    navigate("/viewremitdetails", {
                      state: { docID: data.id, docDATA: data.data() },
                    });
                  }}
                  style={{
                    marginTop: 20,
                    backgroundColor: "white",
                    color: "black",
                    padding: 15,
                    borderRadius: 20,
                    width: 250,
                  }}
                >
                  <p
                    style={{
                      fontSize: 13,
                      textAlign: "center",
                      marginBottom: 10,
                      color: "gray",
                    }}
                  >
                    {formatDate(timestamp)}
                  </p>
                  <h4
                    style={{
                      textAlign: "center",
                      margin: 0,
                      fontWeight: "500",
                    }}
                  >
                    Total Sales
                  </h4>
                  <h3
                    style={{
                      textAlign: "center",
                      margin: 0,
                    }}
                  >
                    PHP {remitDATA.profit.total}.00
                  </h3>
                  <div style={{ display: "flex", flex: 1, marginTop: 10 }}>
                    <div style={{ alignItems: "center", flex: 1 }}>
                      <p
                        style={{ textAlign: "center", margin: 0, fontSize: 12 }}
                      >
                        Owner's Share
                      </p>
                      <p
                        style={{
                          margin: 0,
                          textAlign: "center",
                          fontSize: 15,
                          fontWeight: "600",
                        }}
                      >
                        PHP {remitDATA.profit.myShare}.00
                      </p>
                    </div>
                    <div style={{ alignItems: "center", flex: 1 }}>
                      <p
                        style={{ textAlign: "center", margin: 0, fontSize: 13 }}
                      >
                        Station's Share
                      </p>
                      <p
                        style={{
                          fontSize: 15,
                          margin: 0,
                          textAlign: "center",
                          fontWeight: "600",
                        }}
                      >
                        PHP {remitDATA.profit.stationShare}.00
                      </p>
                    </div>
                  </div>
                  <p
                    style={{
                      fontSize: 11,
                      textAlign: "center",
                      color: "gray",
                      marginTop: 10,
                    }}
                  >
                    Tap for more details
                  </p>
                </div>
              );
            })
          ) : (
            <p style={{ fontSize: 13, color: "lightgray" }}>No remit history</p>
          )}
        </div>
      </div>
    );
  }
};

export default ViewRemitHistory;
