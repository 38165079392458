import React, { useState, useEffect, useRef } from "react";
import { UserAuth } from "../context/AuthContext";
import { useNavigate, useLocation } from "react-router";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
  increment,
  writeBatch,
} from "firebase/firestore";
import { db } from "../firebase";
import LoadingScreen from "./LoadingScreen";
import GoBack from "../components/GoBack";
import { AiFillEye } from "react-icons/ai";
import { ClipLoader } from "react-spinners";

const ViewStation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [stationDATA, setStationDATA] = useState(null);
  const [supplyRequestDATA, setSupplyRequestDATA] = useState(null);
  const [isSupplyRequestDATALoading, setIsSupplyRequestDATALoading] =
    useState(true);
  const stationAccessCode = location.state

  useEffect(() => {
    const docRef = doc(db, "AccessCodes", stationAccessCode);
    const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        console.log(data)
        setStationDATA(data);
      }
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [stationAccessCode]);

  // FOR FETCHING SUPPLY REQUESTS OF THE STATION
  useEffect(() => {
    const fetchData = async () => {
      console.log("fetchesDATA");
      setIsSupplyRequestDATALoading(true);
      const supplyRequestsCollection = collection(db, "SupplyRequests");
      const querySnapshot = await getDocs(
        query(
          supplyRequestsCollection,
          where("accessCode", "==", stationAccessCode),
          where("status", "==", "pending")
        )
      );
      if (querySnapshot.empty) {
        setSupplyRequestDATA(null);
        setIsSupplyRequestDATALoading(false);
      } else {
        setSupplyRequestDATA(querySnapshot.docs);
        setIsSupplyRequestDATALoading(false);
      }
    };

    try {
      setSupplyRequestDATA(null);
      fetchData();
    } catch (error) {
      console.log("Error");
    }
  }, [stationDATA]);

  const EachVoucher = ({ type, quantity }) => {
    return (
      <div
        style={{
          textAlign: "center",
          marginRight: "10px",
          flex: 1,
        }}
      >
        <img
          src={require(`../assets/PHP${type}.png`)}
          alt={`PHP${type}`}
          style={{
            width: "100%",
            objectFit: "contain",
            borderRadius: 10,
          }}
        />
        <p style={{ fontWeight: "400", fontSize: 15 }}>{quantity} pcs</p>
      </div>
    );
  };

  const EachMiniVoucher = ({ type, quantity }) => {
    return (
      <div
        style={{
          textAlign: "center",
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          margin: 5,
        }}
      >
        <img
          src={require(`../assets/PHP${type}.png`)}
          alt={`PHP${type}`}
          style={{
            width: "35%",
            objectFit: "contain",
            borderRadius: 10,
          }}
        />
        <p
          style={{
            fontWeight: "bold",
            fontSize: 15,
            color: "black",
            marginLeft: 5,
          }}
        >
          {quantity}
        </p>
      </div>
    );
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  };

  if (stationDATA) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          overflowX: "hidden", // prevent horizontal scrolling
        }}
      >
        <div
          style={{
            padding: 20,
            width: 300,
          }}
        >
          <GoBack
            onClickFunction={() => {
              navigate("../dashboard", { replace: true });
            }}
          />

          <div style={{ display: "flex" }}>
            {supplyRequestDATA ? (
              <p>Supply Requests</p>
            ) : (
              <p style={{ color: "#ccc" }}>No supply requests</p>
            )}
            {supplyRequestDATA ? (
              <p
                style={{
                  marginLeft: 10,
                  fontSize: 10,
                  fontWeight: "bold",
                  backgroundColor: "red",
                  padding: 5,
                  borderRadius: 100,
                }}
              >
                {supplyRequestDATA.length}
              </p>
            ) : null}
          </div>

          {isSupplyRequestDATALoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ClipLoader color="white" size={20} />
            </div>
          ) : supplyRequestDATA ? (
            supplyRequestDATA.map((data) => {
              // const data = data.data;
              return (
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#e8eaff",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 10,
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      marginBottom: 5,
                      marginTop: 5,
                    }}
                  >
                    <p
                      style={{
                        color: "black",
                        fontSize: 13,
                        fontWeight: "400",
                      }}
                    >
                      {formatDate(data.data().timestamp)}
                    </p>
                  </div>
                  <div style={{ flex: 1, display: "flex" }}>
                    <EachMiniVoucher type={5} quantity={data.data().PHP5} />
                    <EachMiniVoucher type={10} quantity={data.data().PHP10} />
                    <EachMiniVoucher type={15} quantity={data.data().PHP15} />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      onClick={async () => {
                        try {
                          setIsSupplyRequestDATALoading(true);
                          const supplyRequestRef = doc(
                            db,
                            "SupplyRequests",
                            data.id
                          );
                          const stationRef = doc(
                            db,
                            "AccessCodes",
                            stationAccessCode
                          );
                          const notificationRef = doc(
                            db,
                            "Notifications",
                            "SupplyRequests"
                          );
                          const supplyHistoryCollectionRef = collection(
                            db,
                            "SupplyHistory"
                          );
                          const supplyHistoryDocRef = doc(
                            supplyHistoryCollectionRef
                          );

                          const oldSupply = {
                            PHP5: stationDATA.supply.PHP5,
                            PHP10: stationDATA.supply.PHP10,
                            PHP15: stationDATA.supply.PHP15,
                          };
                          const addedSupply = {
                            PHP5: data.data().PHP5,
                            PHP10: data.data().PHP10,
                            PHP15: data.data().PHP15,
                          };
                          const newSupply = {
                            PHP5: oldSupply.PHP5 + addedSupply.PHP5,
                            PHP10: oldSupply.PHP10 + addedSupply.PHP10,
                            PHP15: oldSupply.PHP15 + addedSupply.PHP15,
                          };

                          const batch = writeBatch(db);

                          batch.update(supplyRequestRef, {
                            status: "delivered",
                            deliveryDate: serverTimestamp(),
                          });
                          batch.update(stationRef, {
                            "supply.PHP5": increment(data.data().PHP5),
                            "supply.PHP10": increment(data.data().PHP10),
                            "supply.PHP15": increment(data.data().PHP15),
                          });
                          batch.set(supplyHistoryDocRef, {
                            accessCode: data.data().accessCode,
                            timestamp: serverTimestamp(),
                            oldSupply: oldSupply,
                            addedSupply: addedSupply,
                            newSupply: newSupply,
                            source: "request",
                          });
                          batch.update(notificationRef, {
                            [stationAccessCode]: increment(-1),
                          });
                          await batch.commit();
                        } catch (error) {
                          console.log(error.message);
                        }
                      }}
                      style={{
                        width: "95%",
                        padding: 10,
                        backgroundColor: "#EB455F",
                        borderRadius: 3,
                        fontWeight: "bold",
                        border: "none",
                        margin: 10,
                      }}
                    >
                      <p style={{ color: "white" }}>Done Supply</p>
                    </button>
                  </div>
                </div>
              );
            })
          ) : null}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <AiFillEye size={20} color="#ccc" />
            <p
              style={{
                marginLeft: 5,
                fontSize: 13,
                fontWeight: "300",
                color: "#ccc",
              }}
            >
              You are currently viewing
            </p>
          </div>

          <h2 style={{ margin: 0, color: "#FFDD83" }}>{stationDATA.name}</h2>
          <p>
            Station:{" "}
            <strong style={{ color: "#FFDD83" }}>{stationDATA.station}</strong>
          </p>
          <p>
            Share:{" "}
            <strong style={{ color: "#FFDD83" }}>
              {stationDATA.share * 100 + "%"}
            </strong>
          </p>
          <p>
            Electricity Benefit:{" "}
            <strong style={{ color: "#FFDD83" }}>
              {" "}
              PHP {stationDATA.electricity}
            </strong>
          </p>
          <p>
            Error Codes:{" "}
            <strong style={{ color: "#FFDD83" }}>
              {stationDATA.errorCodes.length}
            </strong>{" "}
          </p>
          <div style={{ marginTop: 20 }}>
            <h3 style={{ margin: 0, marginBottom: 5, color: "white" }}>
              Current Supply
            </h3>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                overflowX: "auto",
                maxWidth: 400,
              }}
            >
              <EachVoucher type={5} quantity={stationDATA.supply.PHP5} />
              <EachVoucher type={10} quantity={stationDATA.supply.PHP10} />
              <EachVoucher type={15} quantity={stationDATA.supply.PHP15} />
            </div>
            <div style={{ marginTop: 20 }}>
              <button
                onClick={() => {
                  navigate("/remit", {
                    state: {
                      stationAccessCode: stationAccessCode,
                      stationDATA: stationDATA,
                    },
                  });
                }}
                style={{
                  width: "100%",
                  backgroundColor: "#EB455F",
                  maxWidth: 400,
                  marginTop: 20,
                  border: "none",
                  borderRadius: 3,
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                <p style={{ margin: 10 }}>Remit Now</p>
              </button>
              <button
                style={{
                  width: "100%",
                  backgroundColor: "#FCFFE7",
                  maxWidth: 400,
                  marginTop: 10,
                  border: "none",
                  borderRadius: 3,
                  color: "black",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  navigate("/viewremithistory", {
                    state: { accessCode: stationAccessCode, userRole: "admin" },
                  });
                }}
              >
                <p style={{ margin: 10 }}>View Remit History</p>
              </button>
              <button
                style={{
                  width: "100%",
                  backgroundColor: "#FCFFE7",
                  maxWidth: 400,
                  marginTop: 10,
                  border: "none",
                  borderRadius: 3,
                  color: "black",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  navigate("/viewsupplyhistory", {
                    state: {
                      userRole: "admin",
                      stationAccessCode: stationAccessCode,
                    },
                  });
                }}
              >
                <p style={{ margin: 10 }}>View Supply History</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <LoadingScreen />;
  }
};

export default ViewStation;
