import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { UserAuth } from "../context/AuthContext";
import { FaPlus } from "react-icons/fa";
import { RiCloseFill } from "react-icons/ri";
import GoBack from "../components/GoBack";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
  writeBatch,
} from "firebase/firestore";
import LoadingScreen from "./LoadingScreen";
import ClipLoader from "react-spinners/ClipLoader";

const DoneRemit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const remitDATA = location.state.remitDATA;
  const remitFailed = location.state.remitFailed;
  const stationDATA = location.state.stationDATA;
  const [remainingSupply, setRemainingSupply] = useState(
    remitDATA.voucher.remaining
  );

  const [showAddSupply, setShowAddSupply] = useState(false);
  const [showDoneAddingSupply, setShowDoneAddingSupply] = useState(false);
  const [isAddSupplyLoading, setIsAddSupplyLoading] = useState(false);
  const [addPHP5, setAddPHP5] = useState(0);
  const [addPHP10, setAddPHP10] = useState(0);
  const [addPHP15, setAddPHP15] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showRemitComputation, setShowRemitComputation] = useState(false);

  const EachVoucher = ({ type, quantity }) => {
    return (
      <div
        style={{
          textAlign: "center",
          marginRight: "10px",
          flex: 1,
        }}
      >
        <img
          src={require(`../assets/PHP${type}.png`)}
          alt={`PHP${type}`}
          style={{
            width: "50%",
            objectFit: "contain",
            borderRadius: 10,
          }}
        />
        <p style={{ fontWeight: "500", fontSize: 15 }}>{quantity} pcs</p>
      </div>
    );
  };

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    if (remitDATA) {
      const supply = remitDATA.supply.old;
      const errorVouchers = remitDATA.voucher.error;
      const finalSupply = {
        PHP5: parseInt(supply.PHP5) - parseInt(errorVouchers.PHP5),
        PHP10: parseInt(supply.PHP10) - parseInt(errorVouchers.PHP10),
        PHP15: parseInt(supply.PHP15) - parseInt(errorVouchers.PHP15),
      };
      const remaining = remitDATA.voucher.remaining;
      const sold = {
        PHP5: parseInt(finalSupply.PHP5) - parseInt(remaining.PHP5),
        PHP10: parseInt(finalSupply.PHP10) - parseInt(remaining.PHP10),
        PHP15: parseInt(finalSupply.PHP15) - parseInt(remaining.PHP15),
      };
      const profit = {
        PHP5: parseInt(sold.PHP5) * 5,
        PHP10: parseInt(sold.PHP10) * 10,
        PHP15: parseInt(sold.PHP15) * 15,
      };
      const totalProfit = remitDATA.profit.total;
      const electricity = remitDATA.electricity
        ? stationDATA.electricityBenefit
        : 0;
      const sharing = {
        myShare: remitDATA.profit.myShare,
        stationShare: remitDATA.profit.stationShare,
      };
      const textCalculation = {
        withElectricity: {
          myShare:
            remitDATA.profit.total +
            " x " +
            (1 - stationDATA.percentageShare) * 100 +
            "% - " +
            stationDATA.electricityBenefit +
            " = " +
            sharing.myShare,
          stationShare:
            remitDATA.profit.total +
            " x " +
            stationDATA.percentageShare * 100 +
            "% + " +
            stationDATA.electricityBenefit +
            " = " +
            sharing.stationShare,
        },
        withoutElectricity: {
          myShare:
            remitDATA.profit.total +
            " x " +
            (1 - stationDATA.percentageShare) * 100 +
            "% = " +
            sharing.myShare,
          stationShare:
            remitDATA.profit.total +
            " x " +
            stationDATA.percentageShare * 100 +
            "% = " +
            sharing.stationShare,
        },
      };
      return (
        <div
          style={{
            backgroundColor: "#083780",
            color: "white",
            margin: "0 auto",
            padding: "20px",
            overflowX: "hidden",
            width: 300,
          }}
        >
          <h2 style={{ margin: 0, color: "#00FFAB", textAlign: "center" }}>
            Remit Successful
          </h2>

          <h3 style={{ textAlign: "center", marginBottom: 0 }}>
            Remit Details
          </h3>

          <div
            style={{
              marginTop: 20,
              border: "0.5px solid white",
              padding: 15,
              borderRadius: 20,
            }}
          >
            <p style={{ fontSize: 13, textAlign: "center", marginBottom: 10 }}>
              {remitDATA.timestamp.toLocaleString()}
            </p>
            <h3 style={{ textAlign: "center", margin: 0 }}>Total Sales</h3>
            <h2
              style={{
                textAlign: "center",
                margin: 0,
                marginTop: 5,
                color: "#FFDD83",
              }}
            >
              PHP {remitDATA.profit.total}.00
            </h2>
            <div style={{ display: "flex", flex: 1, marginTop: 10 }}>
              <div style={{ alignItems: "center", flex: 1 }}>
                <p style={{ textAlign: "center", margin: 0, fontSize: 13 }}>
                  My Share
                </p>
                <h4 style={{ margin: 0, textAlign: "center" }}>
                  PHP {remitDATA.profit.myShare}.00
                </h4>
              </div>
              <div style={{ alignItems: "center", flex: 1 }}>
                <p style={{ textAlign: "center", margin: 0, fontSize: 13 }}>
                  Station's Share
                </p>
                <h4 style={{ margin: 0, textAlign: "center" }}>
                  PHP {remitDATA.profit.stationShare}.00
                </h4>
              </div>
            </div>
            {showRemitComputation ? (
              <div style={{ marginTop: 20 }}>
                <div style={{ fontSize: 13 }}>
                  <h3 style={{ margin: 0 }}>Supply</h3>
                  <p>5 PHP: {supply.PHP5}</p>
                  <p>10 PHP: {supply.PHP10}</p>
                  <p>15 PHP: {supply.PHP15}</p>
                  <h3 style={{ margin: 0, marginTop: 10 }}>Error code</h3>
                  <p>5 PHP: {errorVouchers.PHP5}</p>
                  <p>10 PHP: {errorVouchers.PHP10}</p>
                  <p>15 PHP: {errorVouchers.PHP15}</p>
                  <p style={{ fontSize: 13, marginTop: 10 }}>
                    <strong style={{ fontSize: 15 }}>Final Supply</strong>{" "}
                    (Supply - Error code)
                  </p>
                  <p>
                    5 PHP : {supply.PHP5} - {errorVouchers.PHP5} ={" "}
                    {finalSupply.PHP5}
                  </p>
                  <p>
                    10 PHP: {supply.PHP10} - {errorVouchers.PHP10} ={" "}
                    {finalSupply.PHP10}
                  </p>
                  <p>
                    15 PHP: {supply.PHP15} - {errorVouchers.PHP15} ={" "}
                    {finalSupply.PHP15}
                  </p>
                  <h3 style={{ margin: 0, marginTop: 10 }}>Remaining</h3>
                  <p>5 PHP: {remaining.PHP5}</p>
                  <p>10 PHP: {remaining.PHP10}</p>
                  <p>15 PHP: {remaining.PHP15}</p>
                  <p style={{ fontSize: 13, marginTop: 10 }}>
                    <strong style={{ fontSize: 15 }}>Sold</strong> (Final supply
                    - Remaining)
                  </p>
                  <p>
                    5 PHP: {finalSupply.PHP5} - {remaining.PHP5} = {sold.PHP5}
                  </p>
                  <p>
                    10 PHP: {finalSupply.PHP10} - {remaining.PHP10} ={" "}
                    {sold.PHP10}
                  </p>
                  <p>
                    15 PHP: {finalSupply.PHP15} - {remaining.PHP15} ={" "}
                    {sold.PHP15}
                  </p>
                  <p style={{ fontSize: 13, marginTop: 10 }}>
                    <strong style={{ fontSize: 15 }}>Profit</strong> (Sold x
                    Price)
                  </p>
                  <p>
                    5 PHP: {sold.PHP5} x 5 = {profit.PHP5}
                  </p>
                  <p>
                    10 PHP: {sold.PHP10} x 10 = {profit.PHP10}
                  </p>
                  <p>
                    15 PHP: {sold.PHP15} x 15 = {profit.PHP15}
                  </p>
                  <h3 style={{ margin: 0, marginTop: 10 }}>Total profit</h3>
                  <p>
                    {profit.PHP5} + {profit.PHP10} + {profit.PHP15} ={" "}
                    {totalProfit}
                  </p>
                  <h3 style={{ margin: 0, marginTop: 10 }}>Electricity</h3>
                  <p
                    style={{
                      color: remitDATA.includeElectricity ? "white" : "gray",
                    }}
                  >
                    {remitDATA.includeElectricity
                      ? stationDATA.electricityBenefit
                      : "Not yet"}
                  </p>
                  <h3 style={{ margin: 0, marginTop: 10 }}>Sharing </h3>
                  <p>
                    My Share:{" "}
                    {remitDATA.includeElectricity
                      ? textCalculation.withElectricity.myShare
                      : textCalculation.withoutElectricity.myShare}
                  </p>
                  <p>
                    Station Share:{" "}
                    {remitDATA.includeElectricity
                      ? textCalculation.withElectricity.stationShare
                      : textCalculation.withoutElectricity.stationShare}
                  </p>
                </div>
                <button
                  onClick={() => {
                    setShowRemitComputation(false);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    backgroundColor: "white",
                    maxWidth: 300,
                    marginTop: 10,
                    border: "none",
                    borderRadius: 3,
                    color: "black",
                    fontWeight: "bold",
                    padding: 5,
                  }}
                >
                  <p>Hide computation</p>
                </button>
              </div>
            ) : (
              <button
                onClick={() => {
                  setShowRemitComputation(true);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  backgroundColor: "white",
                  maxWidth: 300,
                  marginTop: 10,
                  border: "none",
                  borderRadius: 3,
                  color: "black",
                  fontWeight: "bold",
                  padding: 5,
                }}
              >
                <p>Show computation</p>
              </button>
            )}
          </div>

          <div
            style={{
              marginTop: 30,
              border: "0.5px solid white",
              padding: 15,
              borderRadius: 20,
            }}
          >
            <h3 style={{ textAlign: "center", margin: 0 }}>New Supply</h3>

            <div
              style={{
                marginTop: 20,
                display: "flex",
                overflowX: "auto",
                maxWidth: 400,
              }}
            >
              <EachVoucher
                type={5}
                quantity={
                  showDoneAddingSupply
                    ? remainingSupply.PHP5 + addPHP5
                    : remainingSupply.PHP5
                }
              />
              <EachVoucher
                type={10}
                quantity={
                  showDoneAddingSupply
                    ? remainingSupply.PHP10 + addPHP10
                    : remainingSupply.PHP10
                }
              />
              <EachVoucher type={15} quantity={remainingSupply.PHP15} />
            </div>
            {isAddSupplyLoading ? (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 50,
                }}
              >
                <ClipLoader color="white" size={20} />
              </div>
            ) : (
              <div>
                <div style={{ marginTop: 30 }}>
                  {showDoneAddingSupply ? (
                    <>
                      <p
                        style={{
                          fontSize: 15,
                          fontWeight: "bold",
                          color: "#00FFAB",
                          textAlign: "center",
                        }}
                      >
                        Successfully added supply
                      </p>
                      <p
                        style={{
                          fontSize: 11,
                          color: "#ccc",
                          textAlign: "center",
                          marginBottom: 10,
                        }}
                      >
                        Supply was successfully added after remit
                      </p>
                    </>
                  ) : showAddSupply ? (
                    <div>
                      <button
                        onClick={() => {
                          setShowAddSupply(false);
                        }}
                        style={{
                          backgroundColor: "#083780",
                          border: "none",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <RiCloseFill color={"white"} size={30} />
                        <p style={{ fontSize: 13, color: "white" }}>Close</p>
                      </button>
                      <h3 style={{ textAlign: "center" }}>Add Supply</h3>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: 20,
                        }}
                      >
                        <img
                          src={require(`../assets/PHP5.png`)}
                          alt={`PHP5`}
                          style={{
                            width: "25%",
                            marginRight: 10,
                            borderRadius: 8,
                          }}
                        />
                        <input
                          placeholder="Quantity"
                          value={addPHP5}
                          onChange={(event) => {
                            setAddPHP5(parseInt(event.target.value));
                          }}
                          type="number"
                          style={{
                            backgroundColor: "white",
                            maxWidth: 80,
                            borderRadius: 8,
                            textAlign: "center",
                            fontSize: 15,
                            fontWeight: "600",
                            height: 30,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: 20,
                        }}
                      >
                        <img
                          src={require(`../assets/PHP10.png`)}
                          alt={`PHP10`}
                          style={{
                            width: "25%",
                            borderRadius: 8,
                            marginRight: 10,
                          }}
                        />
                        <input
                          placeholder="Quantity"
                          value={addPHP10}
                          onChange={(event) => {
                            setAddPHP10(parseInt(event.target.value));
                          }}
                          type="number"
                          style={{
                            backgroundColor: "white",
                            maxWidth: 80,
                            borderRadius: 8,
                            textAlign: "center",
                            fontSize: 15,
                            fontWeight: "600",
                            height: 30,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: 20,
                        }}
                      >
                        <img
                          src={require(`../assets/PHP15.png`)}
                          alt={`PHP15`}
                          style={{
                            width: "25%",
                            borderRadius: 10,
                            marginRight: 10,
                          }}
                        />
                        <input
                          placeholder="Quantity"
                          value={addPHP15}
                          onChange={(event) => {
                            setAddPHP15(parseInt(event.target.value));
                          }}
                          type="number"
                          style={{
                            backgroundColor: "white",
                            maxWidth: 80,
                            borderRadius: 8,
                            textAlign: "center",
                            fontSize: 15,
                            fontWeight: "600",
                            height: 30,
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <p
                        style={{
                          fontSize: 13,
                          textAlign: "center",
                          marginBottom: 10,
                        }}
                      >
                        Want to add supply after remit? Press the button below.
                      </p>
                      <button
                        onClick={() => {
                          setShowAddSupply(true);
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          backgroundColor: "white",
                          maxWidth: 300,
                          border: "none",
                          borderRadius: 3,
                          color: "#FCFFE7",
                          fontWeight: "bold",
                          padding: 10,
                        }}
                      >
                        <FaPlus color={"black"} />
                        <p style={{ color: "black", marginLeft: 10 }}>
                          Add Supply
                        </p>
                      </button>
                    </>
                  )}
                </div>
                {showAddSupply ? (
                  <button
                    onClick={async () => {
                      try {
                        setIsAddSupplyLoading(true);
                        const supplyHistoryCollectionRef = collection(
                          db,
                          "SupplyHistory"
                        );
                        const supplyHistoryDocRef = doc(
                          supplyHistoryCollectionRef
                        );
                        const stationRef = doc(
                          db,
                          "AccessCodes",
                          stationDATA.accessCode
                        );
                        const batch = writeBatch(db);

                        const newPHP5 = remainingSupply.PHP5 + addPHP5;
                        const newPHP10 = remainingSupply.PHP10 + addPHP10;
                        const newPHP15 = remainingSupply.PHP15 + addPHP15;

                        batch.set(supplyHistoryDocRef, {
                          accessCode: stationDATA.accessCode,
                          timestamp: serverTimestamp(),
                          oldSupply: {
                            PHP5: remainingSupply.PHP5,
                            PHP10: remainingSupply.PHP10,
                            PHP15: remainingSupply.PHP15,
                          },
                          addedSupply: {
                            PHP5: addPHP5,
                            PHP10: addPHP10,
                            PHP15: addPHP15,
                          },
                          newSupply: {
                            PHP5: newPHP5,
                            PHP10: newPHP10,
                            PHP15: newPHP15,
                          },
                          source: "remit",
                        });

                        batch.update(stationRef, {
                          supply: {
                            PHP5: newPHP5,
                            PHP10: newPHP10,
                            PHP15: newPHP15,
                          },
                        });

                        await batch.commit();
                        setAddPHP5(0);
                        setAddPHP10(0);
                        setAddPHP15(0);
                        setRemainingSupply({
                          PHP5: newPHP5,
                          PHP10: newPHP10,
                          PHP15: newPHP15,
                        });

                        setIsAddSupplyLoading(false);
                        setShowDoneAddingSupply(true);
                        setShowAddSupply(false);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    style={{
                      width: "100%",
                      backgroundColor:
                        !addPHP5 && !addPHP10 && !addPHP15 ? "#ccc" : "#EB455F",
                      maxWidth: 300,
                      border: "none",
                      borderRadius: 3,
                      color: "#FCFFE7",
                      fontWeight: "bold",
                    }}
                  >
                    <p style={{ margin: 10 }}>Add</p>
                  </button>
                ) : null}
              </div>
            )}
          </div>
          <div style={{ marginTop: 40 }}>
            <button
              onClick={() => {
                navigate("../viewstation", {
                  replace: true,
                  state: stationDATA.accessCode,
                });
              }}
              style={{
                width: "100%",
                backgroundColor: "#EB455F",
                maxWidth: 300,
                border: "none",
                borderRadius: 3,
                color: "#FCFFE7",
                fontWeight: "bold",
              }}
            >
              <p style={{ margin: 10 }}>Finish</p>
            </button>
          </div>
        </div>
      );
    } else {
      if (remitFailed) {
        return (
          <div
            style={{
              backgroundColor: "#083780",
              color: "white",
              margin: "0 auto",
              padding: "20px",
              overflowX: "hidden",
              width: 300,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
              <h1 style={{ margin: 0, marginBottom: 30, color: "#EB455F" }}>
                Remit Failed
              </h1>
              <p
                style={{
                  fontSize: 13,
                  color: "#ccc",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                Please try to remit again
              </p>
              <button
                onClick={() => {
                  navigate("../viewstation", {
                    replace: true,
                    state: stationDATA.accessCode,
                  });
                }}
                style={{
                  width: "100%",
                  backgroundColor: "#EB455F",
                  maxWidth: 300,
                  marginTop: 50,
                  border: "none",
                  borderRadius: 3,
                  color: "#FCFFE7",
                  fontWeight: "bold",
                }}
              >
                <p style={{ margin: 10 }}>Back to Station</p>
              </button>
            </div>
          </div>
        );
      } else {
        return (
          <div
            style={{
              backgroundColor: "#083780",
              color: "white",
              margin: "0 auto",
              padding: "20px",
              overflowX: "hidden",
              width: 300,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
              <h2 style={{ margin: 0, marginBottom: 30, color: "#EB455F" }}>
                Remit data not found
              </h2>
              <p
                style={{
                  fontSize: 13,
                  color: "#ccc",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                Please check Remit History to verify
              </p>
              <button
                onClick={() => {
                  navigate("../viewstation", {
                    replace: true,
                    state: stationDATA.accessCode,
                  });
                }}
                style={{
                  width: "100%",
                  backgroundColor: "#EB455F",
                  maxWidth: 300,
                  marginTop: 50,
                  border: "none",
                  borderRadius: 3,
                  color: "#FCFFE7",
                  fontWeight: "bold",
                }}
              >
                <p style={{ margin: 10 }}>Back to Station</p>
              </button>
            </div>
          </div>
        );
      }
    }
  }
};

export default DoneRemit;
