import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { FaArrowLeft } from "react-icons/fa";
import GoBack from "../components/GoBack";

const ViewUnliCodes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const unliCodes = location.state;

  return (
    <div
      style={{
        backgroundColor: "#083780",
        color: "white",
        margin: "0 auto",
        padding: "20px",
        overflowX: "hidden",
        width: 300,
      }}
    >
      <GoBack
        onClickFunction={() => {
          navigate("../dashboard", { replace: true });
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 30,
        }}
      >
        <h2 style={{ margin: 0, marginBottom: 30 }}>Unli Codes</h2>
        {unliCodes.map((unliCode) => {
          return <p style={{ marginBottom: 10 }}>{unliCode}</p>;
        })}
      </div>
    </div>
  );
};

export default ViewUnliCodes;
