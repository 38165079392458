import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { UserAuth } from "../context/AuthContext";
import GoBack from "../components/GoBack";
import { db } from "../firebase";
import LoadingScreen from "./LoadingScreen";
import {
  collection,
  getDocs,
  where,
  query,
  orderBy,
  Timestamp,
  updateDoc,
  doc,
  arrayUnion,
} from "firebase/firestore";

const RemitAllStations = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accessCode, userRole } = UserAuth();
  const allStationsDATA = location.state.allStationsDATA;
  const subAdminName = location.state.subAdminName;
  const [isLoading, setIsLoading] = useState(false);
  const subAdminShare = (1 - allStationsDATA[0].share) / 2;
  const adnelShare = (1 - allStationsDATA[0].share) / 2;
  const stationShare = allStationsDATA[0].share;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const formatDate2 = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const formatPrice = (amount) => {
    // Function to format number to Philippine peso currency
    const formatToPhilippineCurrency = (value) => {
      // Convert value to a string and split it into whole and decimal parts
      const [wholePart, decimalPart] = value.toString().split(".");

      // Format whole part with comma separator every 3 digits
      const formattedWholePart = wholePart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      // If there's a decimal part, add it with 2 decimal places; otherwise, add .00
      const formattedDecimalPart = decimalPart
        ? `.${decimalPart.padEnd(2, "0")}`
        : ".00";

      // Return the formatted currency string with the Philippine peso symbol
      return `₱${formattedWholePart}${formattedDecimalPart}`;
    };

    // Call the formatting function and return the result
    return formatToPhilippineCurrency(amount);
  };

  const calculateTotalRemittance = (managedStations) => {
    // Initialize total remittance
    let totalRemittance = 0;

    // Iterate over the managedStations array and sum up the remittance of each station
    managedStations.forEach((station) => {
      totalRemittance += station.remittance;
    });

    return totalRemittance;
  };

  const updateStationsData = (stationsData) => {
    // Reset remittance
    const updatedStationsData = stationsData.map((station) => {
      return {
        ...station,
        remittance: 0,
        shareAmount: 0,
      };
    });

    return updatedStationsData;
  };

  const generateAdminShares = (stationsData) => {
    // Calculate total remittance and share for applicable stations
    const totalRemittance = stationsData.reduce(
      (acc, station) => acc + station.remittance,
      0
    );
    const totalShareAmount = stationsData.reduce((acc, station) => {
      if (!station.advanceRemit) {
        return acc + station.shareAmount;
      } else {
        return acc;
      }
    }, 0);

    // Calculate share for subadmin and adnel
    const subadminShareAmount = (totalRemittance - totalShareAmount) / 2;
    const adnelShareAmount = (totalRemittance - totalShareAmount) / 2;

    const shareAmount = { subadminShareAmount, adnelShareAmount };

    return shareAmount;
  };

  const generateRemitHistoryData = (stationsData, subadminName) => {
    // Calculate total remittance and share for applicable stations
    const totalRemittance = stationsData.reduce(
      (acc, station) => acc + station.remittance,
      0
    );
    const totalShareAmount = stationsData.reduce((acc, station) => {
      if (station.advanceRemit) {
        return acc;
      } else {
        return acc + station.shareAmount;
      }
    }, 0);

    // Calculate share for subadmin and adnel
    const subadminShareAmount = (totalRemittance - totalShareAmount) / 2;
    const adnelShareAmount = (totalRemittance - totalShareAmount) / 2;

    // Generate remit history data
    const remitHistoryData = {
      totalRemittance: totalRemittance,
      share: {
        station: {
          percentage: stationShare,
          amount: totalShareAmount,
        },
        subAdmin: {
          percentage: subAdminShare,
          amount: subadminShareAmount,
        },
        adnel: { percentage: adnelShare, amount: adnelShareAmount },
      },
      stationsDATA: stationsData.map((station) => ({
        id: station.id,
        name: station.name,
        remittance: station.remittance, // Keep the remittance intact
        share: station.share,
        shareAmount: station.advanceRemit ? 0 : station.shareAmount,
      })),
      timestamp: Timestamp.now(), // Assuming Timestamp is defined elsewhere
    };

    return remitHistoryData;
  };

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <div
        style={{
          backgroundColor: "#083780",
          color: "white",
          margin: "0 auto",
          padding: "20px",
          overflowX: "hidden",
          width: 300,
        }}
      >
        <GoBack
          onClickFunction={() => {
            navigate("../dashboard", {
              replace: true,
            });
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2 style={{ margin: 0, marginBottom: 10 }}>Remit All Station</h2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
            marginTop: 30,
          }}
        >
          <p style={{ fontWeight: "bold" }}>Station</p>
          <p style={{ fontWeight: "bold" }}>Remittance</p>
        </div>
        {allStationsDATA.map((station) => {
          return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>{station.name}</p>
              <p>{formatPrice(station.remittance)}</p>
            </div>
          );
        })}
        <p style={{ textAlign: "center", marginTop: 30 }}>Total Remittance</p>
        <h1 style={{ margin: 0, textAlign: "center" }}>
          {formatPrice(calculateTotalRemittance(allStationsDATA))}
        </h1>
        <div
          style={{
            backgroundColor: "#FFDD83",
            color: "black",
            borderRadius: 20,
            padding: 15,
            marginTop: 10,
          }}
        >
          <h2 style={{ textAlign: "center", margin: 0 }}>Sharing</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
              marginTop: 30,
            }}
          >
            <p style={{ fontWeight: "bold" }}>Station</p>
            <p style={{ fontWeight: "bold" }}>Share</p>
          </div>
          {allStationsDATA.map((station) => {
            return (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p>{station.name}</p>
                <p>{formatPrice(station.shareAmount)}</p>
              </div>
            );
          })}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p>{subAdminName}</p>
              <p>Adnel</p>
            </div>
            <div>
              <p>
                {formatPrice(
                  generateAdminShares(allStationsDATA).subadminShareAmount
                )}
              </p>
              <p>
                {formatPrice(
                  generateAdminShares(allStationsDATA).adnelShareAmount
                )}
              </p>
            </div>
          </div>
        </div>
        <button
          style={{
            color: "white",
            marginTop: 10,
            flex: 1,
            width: "100%",
            marginLeft: 2.5,
            marginRight: 2.5,
            backgroundColor: "#EB455F",
            border: "none",
            borderRadius: 5,
            padding: 10,
            fontWeight: "bold",
            marginTop: 20,
          }}
          onClick={async () => {
            try {
              const subAdminRef = doc(db, "AccessCodes", accessCode);
              setIsLoading(true);
              await updateDoc(subAdminRef, {
                managedStations: updateStationsData(allStationsDATA),
                remitHistory: arrayUnion(
                  generateRemitHistoryData(allStationsDATA, subAdminName)
                ),
                transactionHistory: [],
              });
              setIsLoading(false);
              navigate("../dashboard", { replace: true });
            } catch (error) {
              setIsLoading(false);
              console.log(error);
            }
            // generateRemitHistoryData(allStationsDATA, subAdminName);
          }}
        >
          <p style={{ fontSize: 12 }}>Confirm Remittance</p>
        </button>
      </div>
    );
  }
};

export default RemitAllStations;
