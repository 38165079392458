import React, { useState, useEffect } from "react";
import LoadingScreen from "./LoadingScreen";
import {
  collection,
  getDocs,
  where,
  query,
  deleteDoc,
  doc,
  onSnapshot, // Import onSnapshot
  updateDoc,
  increment,
} from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase";
import GoBack from "../components/GoBack";
import { useNavigate, useLocation } from "react-router";

const ViewSupplyRequests = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { accessCode } = UserAuth();
  const [supplyRequestDATA, setSupplyRequestDATA] = useState(null);
  const navigate = useNavigate();

  const handleCancelRequest = async (documentId) => {
    try {
      const supplyRequestDocRef = doc(db, "SupplyRequests", documentId);
      await deleteDoc(supplyRequestDocRef).then(async () => {
        const notificationRef = doc(db, "Notifications", "SupplyRequests");
        updateDoc(notificationRef, { [accessCode]: increment(-1) });
      });
      console.log(`Document with ID ${documentId} successfully deleted.`);
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const EachMiniVoucher = ({ type, quantity }) => {
    return (
      <div
        style={{
          textAlign: "center",
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          margin: 5,
        }}
      >
        <img
          src={require(`../assets/PHP${type}.png`)}
          alt={`PHP${type}`}
          style={{
            width: "25%",
            objectFit: "contain",
            borderRadius: 10,
            opacity: quantity === 0 ? 0.3 : 1,
          }}
        />
        <p
          style={{
            fontWeight: "bold",
            fontSize: 17,
            opacity: quantity === 0 ? 0.3 : 1,
            color: quantity === 0 ? "#ccc" : "white",
            marginLeft: 5,
          }}
        >
          {quantity} PCS
        </p>
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const supplyRequestCollection = collection(db, "SupplyRequests");
      const querySnapshot = await getDocs(
        query(supplyRequestCollection, where("accessCode", "==", accessCode))
      );
      setSupplyRequestDATA(querySnapshot.docs);
      setIsLoading(false);

      // Set up real-time updates using onSnapshot
      const unsubscribe = onSnapshot(
        query(supplyRequestCollection, where("accessCode", "==", accessCode)),
        (snapshot) => {
          setSupplyRequestDATA(snapshot.docs);
        }
      );

      // Clean up the subscription when the component unmounts
      return () => unsubscribe();
    };

    fetchData();
  }, [accessCode]);

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <div
        style={{
          backgroundColor: "#083780",
          color: "white",
          margin: "0 auto",
          padding: "20px",
          overflowX: "hidden",
          width: 300,
        }}
      >
        <GoBack
          onClickFunction={() => {
            navigate("../dashboard", { replace: true });
          }}
        />
        <h2 style={{ textAlign: "center" }}>Supply Requests</h2>
        {supplyRequestDATA.length > 0 ? (
          <div>
            {supplyRequestDATA
              .sort((a, b) => b.data().timestamp - a.data().timestamp)
              .map((doc) => (
                <div
                  key={doc.id}
                  style={{
                    border:
                      doc.data().status === "delivered"
                        ? "2px solid #00FFAB"
                        : "2px solid white",
                    borderRadius: 10,
                    padding: 10,
                    marginBottom: 20,
                  }}
                >
                  {/* Displaying the document ID for each document */}
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 13,
                    }}
                  >
                    Requested on
                  </p>
                  <p
                    style={{
                      marginTop: 2.5,
                      marginBottom: 10,
                      textAlign: "center",
                      fontSize: 10,
                    }}
                  >
                    {formatDate(doc.data().timestamp)}
                  </p>
                  <div style={{ flex: 1, display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <div
                        style={{
                          flex: 1,
                          marginBottom: 5,
                        }}
                      >
                        <EachMiniVoucher type={5} quantity={doc.data().PHP5} />
                      </div>
                      <div
                        style={{
                          flex: 1,
                          marginBottom: 5,
                        }}
                      >
                        <EachMiniVoucher
                          type={10}
                          quantity={doc.data().PHP10}
                        />
                      </div>
                      <div
                        style={{
                          flex: 1,
                          marginBottom: 5,
                        }}
                      >
                        <EachMiniVoucher
                          type={15}
                          quantity={doc.data().PHP15}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        marginLeft: 10,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {doc.data().status === "pending" ? (
                        <h3
                          style={{ padding: 0, margin: 0, textAlign: "center" }}
                        >
                          PENDING
                        </h3>
                      ) : doc.data().status === "processing" ? (
                        <h3
                          style={{ padding: 0, margin: 0, textAlign: "center" }}
                        >
                          PROCESSING
                        </h3>
                      ) : doc.data().status === "delivered" ? (
                        <div>
                          <h3
                            style={{
                              padding: 0,
                              margin: 0,
                              color: "#00FFAB",
                              textAlign: "center",
                            }}
                          >
                            DELIVERED
                          </h3>
                          <p style={{ fontSize: 10 }}>
                            {formatDate(doc.data().deliveryDate)}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {doc.data().status === "delivered" ||
                  doc.data().status === "processing" ? null : (
                    <button
                      onClick={() => handleCancelRequest(doc.id)}
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        marginTop: 5,
                        padding: 7,
                        border: "none",
                        borderRadius: 3,
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      <p>Cancel Request</p>
                    </button>
                  )}
                </div>
              ))}
          </div>
        ) : (
          <p
            style={{
              textAlign: "center",
              marginTop: 20,
              fontSize: 13,
              color: "#ccc",
            }}
          >
            No request
          </p>
        )}
      </div>
    );
  }
};

export default ViewSupplyRequests;
