import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import GoBack from "../components/GoBack";

const Remit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stationAccessCode = location.state.stationAccessCode;
  const stationDATA = location.state.stationDATA;
  const [remainingPHP5, setRemainingPHP5] = useState(null);
  const [remainingPHP10, setRemainingPHP10] = useState(null);
  const [remainingPHP15, setRemainingPHP15] = useState(null);
  const [remainingPHP20, setRemainingPHP20] = useState(null);
  const [showError, setShowError] = useState(false);

  return (
    <div
      style={{
        backgroundColor: "#083780",
        color: "white",
        margin: "0 auto",
        padding: "20px",
        overflowX: "hidden",
        width: 300,
      }}
    >
      <GoBack
        onClickFunction={() => {
          navigate("/viewstation", {
            replace: true,
            state: stationAccessCode,
          });
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 30,
        }}
      >
        <h2 style={{ margin: 0, marginBottom: 30 }}>Remit</h2>
        <p style={{ fontSize: 13, marginBottom: 20 }}>
          Count the <strong>REMAINING</strong> vouchers
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
          }}
        >
          <img
            src={require(`../assets/PHP5.png`)}
            alt={`PHP5`}
            style={{
              width: "35%",
              marginRight: 10,
              borderRadius: 8,
            }}
          />
          <input
            placeholder="Quantity"
            value={remainingPHP5}
            onChange={(event) => {
              setRemainingPHP5(event.target.value);
            }}
            type="number"
            style={{
              backgroundColor: "white",
              maxWidth: 80,
              borderRadius: 8,
              textAlign: "center",
              fontSize: 15,
              fontWeight: "600",
              height: 30,
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
          }}
        >
          <img
            src={require(`../assets/PHP10.png`)}
            alt={`PHP10`}
            style={{
              width: "35%",
              borderRadius: 8,
              marginRight: 10,
            }}
          />
          <input
            placeholder="Quantity"
            value={remainingPHP10}
            onChange={(event) => {
              setRemainingPHP10(event.target.value);
            }}
            type="number"
            style={{
              backgroundColor: "white",
              maxWidth: 80,
              borderRadius: 8,
              textAlign: "center",
              fontSize: 15,
              fontWeight: "600",
              height: 30,
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
          }}
        >
          <img
            src={require(`../assets/PHP15.png`)}
            alt={`PHP15`}
            style={{
              width: "35%",
              borderRadius: 10,
              marginRight: 10,
            }}
          />
          <input
            placeholder="Quantity"
            value={remainingPHP15}
            onChange={(event) => {
              setRemainingPHP15(event.target.value);
            }}
            type="number"
            style={{
              backgroundColor: "white",
              maxWidth: 80,
              borderRadius: 8,
              textAlign: "center",
              fontSize: 15,
              fontWeight: "600",
              height: 30,
            }}
          />
        </div>
        {showError ? (
          <div>
            <p style={{ fontSize: 10, color: "red", textAlign: "center" }}>
              Remaining supply is greater than the supply. Please check again.
            </p>
          </div>
        ) : null}
        <button
          disabled={!remainingPHP5 || !remainingPHP10 || !remainingPHP15}
          style={{
            width: "100%",
            backgroundColor:
              !remainingPHP5 || !remainingPHP10 || !remainingPHP15
                ? "#ccc"
                : "#EB455F",
            maxWidth: 400,
            marginTop: 20,
            border: "none",
            borderRadius: 3,
            color: "#FCFFE7",
            fontWeight: "bold",
          }}
          onClick={() => {
            if (
              remainingPHP5 > stationDATA.supply.PHP5 ||
              remainingPHP10 > stationDATA.supply.PHP10 ||
              remainingPHP15 > stationDATA.supply.PHP15
            ) {
              setShowError(true);
            } else {
              navigate("/includeelectricity", {
                state: {
                  remainingPHP5: remainingPHP5,
                  remainingPHP10: remainingPHP10,
                  remainingPHP15: remainingPHP15,
                  stationAccessCode: stationAccessCode,
                  stationDATA: stationDATA,
                },
                replace: true,
              });
            }
          }}
        >
          <p style={{ margin: 10 }}>Next</p>
        </button>
      </div>
    </div>
  );
};

export default Remit;
