import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import GoBack from "../components/GoBack";

const IncludeElectricity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stationAccessCode = location.state.stationAccessCode;
  const stationDATA = location.state.stationDATA;
  const remainingPHP5 = location.state.remainingPHP5;
  const remainingPHP10 = location.state.remainingPHP10;
  const remainingPHP15 = location.state.remainingPHP15;
  const [includeElectricity, setIncludeElectricity] = useState(null);

  return (
    <div
      style={{
        backgroundColor: "#083780",
        color: "white",
        margin: "0 auto",
        padding: "20px",
        overflowX: "hidden",
        width: 300,
      }}
    >
      <GoBack
        onClickFunction={() => {
          navigate("/viewstation", {
            replace: true,
            state: stationAccessCode,
          });
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 30,
        }}
      >
        <h3 style={{ margin: 0 }}>Include Electricity Fee?</h3>
        <p style={{ fontSize: 13, marginBottom: 20, marginTop: 10 }}>
          Only include electricity every end of the month
        </p>
        <button
          onClick={() => {
            setIncludeElectricity(true);
          }}
          style={{
            height: 50,
            width: 100,
            backgroundColor: includeElectricity ? "#FFDD83" : "white",
            border: "none",
            color: "black",
            borderRadius: 10,
            marginBottom: 10,
          }}
        >
          <p
            style={{
              fontSize: includeElectricity ? 18 : 15,
              fontWeight: includeElectricity ? "600" : "400",
            }}
          >
            Yes
          </p>
        </button>
        <button
          onClick={() => {
            setIncludeElectricity(false);
          }}
          style={{
            height: 50,
            width: 100,
            backgroundColor: includeElectricity === false ? "#FFDD83" : "white",
            border: "none",
            color: "black",
            borderRadius: 10,
            marginBottom: 10,
          }}
        >
          <p
            style={{
              fontSize: includeElectricity === false ? 18 : 15,
              fontWeight: includeElectricity === false ? "600" : "400",
            }}
          >
            No
          </p>
        </button>
        <button
          disabled={includeElectricity === null}
          style={{
            width: "100%",
            backgroundColor: includeElectricity === null ? "#ccc" : "#EB455F",
            maxWidth: 300,
            marginTop: 20,
            border: "none",
            borderRadius: 3,
            color: "#FCFFE7",
            fontWeight: "bold",
          }}
          onClick={() => {
            navigate("/confirmremit", {
              state: {
                remainingPHP5: remainingPHP5,
                remainingPHP10: remainingPHP10,
                remainingPHP15: remainingPHP15,
                stationAccessCode: stationAccessCode,
                stationDATA: stationDATA,
                includeElectricity: includeElectricity,
              },
              replace: true,
            });
          }}
        >
          <p style={{ margin: 10 }}>Next</p>
        </button>
      </div>
    </div>
  );
};

export default IncludeElectricity;
