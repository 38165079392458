import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import GoBack from "../components/GoBack";
import { db } from "../firebase";
import LoadingScreen from "./LoadingScreen";
import { collection, getDocs, where, query, orderBy } from "firebase/firestore";
import { RiHomeWifiFill } from "react-icons/ri";
import { UserAuth } from "../context/AuthContext";

const ViewSubAdminSupplyHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accessCode } = UserAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [supplyHistoryDATA, setSupplyHistoryDATA] = useState(null);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const formatDate2 = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const formatPrice = (amount) => {
    // Function to format number to Philippine peso currency
    const formatToPhilippineCurrency = (value) => {
      // Convert value to a string and split it into whole and decimal parts
      const [wholePart, decimalPart] = value.toString().split(".");

      // Format whole part with comma separator every 3 digits
      const formattedWholePart = wholePart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      // If there's a decimal part, add it with 2 decimal places; otherwise, add .00
      const formattedDecimalPart = decimalPart
        ? `.${decimalPart.padEnd(2, "0")}`
        : ".00";

      // Return the formatted currency string with the Philippine peso symbol
      return `₱${formattedWholePart}${formattedDecimalPart}`;
    };

    // Call the formatting function and return the result
    return formatToPhilippineCurrency(amount);
  };

  useEffect(() => {
    if (accessCode) {
      const fetchSupplyDATA = async () => {
        try {
          setIsLoading(true);
          const supplyHistoryCollectionRef = collection(db, "SupplyHistory");
          const querySnapshot = await getDocs(
            query(
              supplyHistoryCollectionRef,
              where("accessCode", "==", accessCode),
              orderBy("timestamp", "desc")
            )
          );

          if (querySnapshot.empty) {
            setSupplyHistoryDATA(null);
          } else {
            setSupplyHistoryDATA(querySnapshot.docs);
          }

          setIsLoading(false);
        } catch (error) {
          console.log(error.message);
        }
      };

      fetchSupplyDATA();
    }
  }, [accessCode]);

  const EachMiniVoucher = ({ type, quantity }) => {
    return (
      <div
        style={{
          textAlign: "center",
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          margin: 5,
        }}
      >
        <img
          src={require(`../assets/PHP${type}.png`)}
          alt={`PHP${type}`}
          style={{
            width: "35%",
            objectFit: "contain",
            borderRadius: 10,
          }}
        />
        <p
          style={{
            fontWeight: "bold",
            fontSize: 15,
            color: "black",
            marginLeft: 5,
          }}
        >
          {quantity}
        </p>
      </div>
    );
  };

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <div
        style={{
          backgroundColor: "#083780",
          color: "white",
          margin: "0 auto",
          padding: "20px",
          overflowX: "hidden",
          width: 300,
        }}
      >
        <GoBack
          onClickFunction={() => {
            navigate("../dashboard", {
              replace: true,
            });
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2 style={{ margin: 0, marginBottom: 10 }}>Supply History</h2>
          {supplyHistoryDATA ? (
            supplyHistoryDATA.map((data) => {
              const timestamp = data.data().timestamp;
              const addedSupply = data.data().addedSupply;
              const oldSupply = data.data().oldSupply;
              const newSupply = data.data().newSupply;
              const source = data.data().source;
              return (
                <div
                  style={{
                    marginTop: 20,
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: 20,
                    width: 250,
                  }}
                >
                  <p
                    style={{
                      fontSize: 13,
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                      paddingTop: 15,
                      paddingLeft: 15,
                      paddingRight: 15,
                    }}
                  >
                    {formatDate(timestamp)}
                  </p>
                  <p
                    style={{
                      fontSize: 11,
                      color: "gray",
                      textAlign: "center",
                      margin: 0,
                      marginBottom: 10,
                    }}
                  >
                    {source === "request"
                      ? "Made through request"
                      : "Made after remit"}
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      margin: 0,
                      fontSize: 12,
                      fontWeight: "600",
                    }}
                  >
                    Old Supply
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      marginBottom: 10,
                    }}
                  >
                    <div style={{ flex: 1, display: "flex", maxWidth: 150 }}>
                      <EachMiniVoucher type={5} quantity={oldSupply.PHP5} />
                      <EachMiniVoucher type={10} quantity={oldSupply.PHP10} />
                      <EachMiniVoucher type={15} quantity={oldSupply.PHP15} />
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#FFDD83",
                      paddingLeft: 15,
                      paddingRight: 15,
                      paddingTop: 10,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        margin: 0,
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "gray",
                      }}
                    >
                      SUPPLY ADDED
                    </p>
                    <div style={{ flex: 1, display: "flex", marginBottom: 10 }}>
                      <EachMiniVoucher type={5} quantity={addedSupply.PHP5} />
                      <EachMiniVoucher type={10} quantity={addedSupply.PHP10} />
                      <EachMiniVoucher type={15} quantity={addedSupply.PHP15} />
                    </div>
                  </div>

                  <p
                    style={{
                      textAlign: "center",
                      margin: 0,
                      fontSize: 12,
                      fontWeight: "600",
                      marginTop: 10,
                    }}
                  >
                    New Supply
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      paddingBottom: 15,
                    }}
                  >
                    <div style={{ flex: 1, display: "flex", maxWidth: 150 }}>
                      <EachMiniVoucher type={5} quantity={newSupply.PHP5} />
                      <EachMiniVoucher type={10} quantity={newSupply.PHP10} />
                      <EachMiniVoucher type={15} quantity={newSupply.PHP15} />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p style={{ fontSize: 13, color: "lightgray", marginTop: 20 }}>
              No supply history
            </p>
          )}
        </div>
      </div>
    );
  }
};

export default ViewSubAdminSupplyHistory;
