import React, { useState, useEffect, useRef } from "react";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
  Timestamp,
  writeBatch,
  FieldValue,
} from "firebase/firestore";
import { db } from "../firebase";
import LoadingScreen from "./LoadingScreen";
import { RiHomeWifiFill } from "react-icons/ri";
import { RiCloseFill } from "react-icons/ri";
import ReactLoading from "react-loading";

const Dashboard = () => {
  const navigate = useNavigate();
  const { accessCode, userRole } = UserAuth();
  const [DATA, setDATA] = useState(null);
  const [notificationDATA, setNotificationDATA] = useState(null);
  const [isNotificationLoading, setIsNotificationLoading] = useState(false);
  // color coding
  // #0066EF blue
  // #FF3131 red
  // #7ED957 green
  // #00FFAB wifi green
  // #EB455F main button red

  // for subadmin
  const [isRemitShowed, setIsRemitShowed] = useState(null);
  const [isAddSupplyShowed, setIsAddSupplyShowed] = useState(null);
  const [stationLoading, setStationLoading] = useState(null);
  const [error, setError] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(null);
  const [errorAddSupply, setErrorAddSupply] = useState(false);

  useEffect(() => {
    const docRef = doc(db, "AccessCodes", accessCode);
    const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      const data = docSnapshot.data();
      setDATA(data);
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [accessCode]);

  useEffect(() => {
    if (userRole === "admin") {
      const docRef = doc(db, "Notifications", "SupplyRequests");
      const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
        const data = docSnapshot.data();
        setNotificationDATA(data);
      });

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [userRole]);

  const EachVoucher = ({ type, quantity }) => {
    return (
      <div
        style={{
          textAlign: "center",
          marginRight: "10px",
          flex: 1,
        }}
      >
        <img
          src={require(`../assets/PHP${type}.png`)}
          alt={`PHP${type}`}
          style={{
            width: "100%",
            objectFit: "contain",
            borderRadius: 10,
          }}
        />
        <p style={{ fontWeight: "400", fontSize: 18 }}>{quantity} pcs</p>
      </div>
    );
  };

  const EachVoucher2 = ({ type, quantity }) => {
    return (
      <div
        style={{
          textAlign: "center",
          marginRight: "10px",
          flex: 1,
        }}
      >
        <img
          src={require(`../assets/PHP${type}.png`)}
          alt={`PHP${type}`}
          style={{
            width: "75%",
            objectFit: "contain",
            borderRadius: 10,
          }}
        />
        <p style={{ fontWeight: "400", fontSize: 13, fontWeight: "bold" }}>
          {quantity} pcs
        </p>
      </div>
    );
  };

  const EachMiniVoucher = ({ type, quantity }) => {
    return (
      <div
        style={{
          textAlign: "center",
          marginRight: "5px",
          flex: 1,
        }}
      >
        <img
          src={require(`../assets/PHP${type}.png`)}
          alt={`PHP${type}`}
          style={{
            width: "100%",
            objectFit: "contain",
            borderRadius: 3,
          }}
        />
        <p style={{ fontWeight: "400", fontSize: 10 }}>{quantity}</p>
      </div>
    );
  };

  const EachMiniVoucher2 = ({ price, quantity, timestamp }) => {
    return (
      <div
        style={{
          textAlign: "center",
          flex: 1,
          display: "flex",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <img
          src={require(`../assets/PHP${price}.png`)}
          alt={`PHP${price}`}
          style={{
            width: "10%",
            objectFit: "contain",
            borderRadius: 3,
            marginRight: 5,
          }}
        />
        <p style={{ fontWeight: "500", fontSize: 13, marginRight: 5 }}>
          {quantity}
        </p>
        <p
          style={{
            fontWeight: "500",
            fontSize: 8,
            marginRight: 5,
            color: "gray",
          }}
        >
          from {formatDate(timestamp)}
        </p>
      </div>
    );
  };

  const EachButton = ({ onClickFunction, text }) => {
    return (
      <button
        onClick={onClickFunction}
        style={{
          flex: 1,
          marginLeft: 2.5,
          marginRight: 2.5,
          backgroundColor: "#f79256",
          border: "none",
          fontSize: 10,
          borderRadius: 5,
          padding: 10,
          fontWeight: "bold",
        }}
      >
        {text}
      </button>
    );
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const formatPrice = (amount) => {
    // Function to format number to Philippine peso currency
    const formatToPhilippineCurrency = (value) => {
      // Convert value to a string and split it into whole and decimal parts
      const [wholePart, decimalPart] = value.toString().split(".");

      // Format whole part with comma separator every 3 digits
      const formattedWholePart = wholePart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      // If there's a decimal part, add it with 2 decimal places; otherwise, add .00
      const formattedDecimalPart = decimalPart
        ? `.${decimalPart.padEnd(2, "0")}`
        : ".00";

      // Return the formatted currency string with the Philippine peso symbol
      return `₱${formattedWholePart}${formattedDecimalPart}`;
    };

    // Call the formatting function and return the result
    return formatToPhilippineCurrency(amount);
  };

  const handleCheckboxChange = (
    index,
    price,
    quantity,
    advanceRemit,
    share
  ) => {
    const newCheckedItems = { ...checkedItems };
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);

    const totalPriceIncrement = newCheckedItems[index]
      ? price * quantity
      : -price * quantity;

    if (advanceRemit === true) {
      setTotalPrice(
        totalPrice + (totalPriceIncrement - totalPriceIncrement * share)
      );
    } else if (advanceRemit === false) {
      setTotalPrice(totalPrice + totalPriceIncrement);
    } else {
      console.log("no value for advance remit");
    }
  };

  const getNewSupplyList = (station, checkedItems) => {
    const newSupplyList = station.supplyList.filter(
      (supply, index) => !checkedItems[index]
    );

    return newSupplyList;
  };

  const getRemittedSupplyList = (station, checkedItems) => {
    const remittedSupplyList = station.supplyList.filter(
      (supply, index) => checkedItems[index]
    );

    return remittedSupplyList;
  };

  const getUpdatedStationList = (
    managedStations,
    id,
    newSupplyList,
    remittedSupplyList
  ) => {
    // Find the index of the item with the specified id
    const index = managedStations.findIndex((station) => station.id === id);

    // Create a new array with the updated item replacing the old one
    const updatedStation = {
      ...managedStations[index],
      supplyList: newSupplyList,
      shareAmount:
        managedStations[index].shareAmount +
        calculateShareAmount(
          totalPrice,
          managedStations[index].share,
          managedStations[index].advanceRemit
        ),
      remittance: managedStations[index].remittance + totalPrice,
      supply: { ...managedStations[index].supply }, // Make a copy of the supply object
    };

    // Deduct quantities from supply based on remittedSupplyList
    remittedSupplyList.forEach((supply) => {
      const { price, quantity } = supply;
      const supplyKey = `PHP${price}`; // Assuming the supply key follows the format PHP{price}
      if (updatedStation.supply.hasOwnProperty(supplyKey)) {
        updatedStation.supply[supplyKey] -= quantity;
      }
    });

    const updatedStations = [...managedStations];
    updatedStations[index] = updatedStation;

    return updatedStations;
  };

  const updateDatabase = async (station, remittedSupplyList, newSupplyList) => {
    try {
      const batch = writeBatch(db);
      const subAdminRef = doc(db, "AccessCodes", accessCode);
      // update transaction history
      batch.update(subAdminRef, {
        managedStations: getUpdatedStationList(
          DATA.managedStations,
          station.id,
          newSupplyList,
          remittedSupplyList
        ),
        transactionHistory: arrayUnion({
          transaction: "remit",
          amount: totalPrice,
          shareAmount: calculateShareAmount(
            totalPrice,
            station.share,
            station.advanceRemit
          ),
          id: station.id,
          name: station.name,
          remittance: remittedSupplyList,
          timestamp: Timestamp.now(),
        }),
      });

      // CONTINUE HERE
      await batch.commit();
    } catch (error) {
      setStationLoading(null);
      setError(station.id);
    }
  };

  const calculateShareAmount = (remittance, share, advanceRemit) => {
    if (advanceRemit) {
      return 0;
    } else {
      return remittance * share;
    }
  };

  const filterAndSortTransactionHistory = (transactionHistory, station) => {
    // Filter the transaction history array based on station id and name
    const filteredHistory = transactionHistory.filter((item) => {
      return item.id === station.id && item.name === station.name;
    });

    // Sort the filtered history array in descending order based on timestamp
    const sortedHistory = filteredHistory.sort(
      (a, b) => b.timestamp - a.timestamp
    );

    return sortedHistory;
  };

  const sortReversedRemitHistory = (remitHistory) => {
    return remitHistory.slice().sort((a, b) => b.timestamp - a.timestamp);
  };

  const updateShareAmountToNull = (stationToUpdate) => {
    const updatedStations = DATA.managedStations.map((station) => {
      if (station.id === stationToUpdate.id) {
        // Update shareAmount to null for the specified station
        return { ...station, shareAmount: 0 };
      }
      return station;
    });
    // Update the managedStations array with the updatedStations
    return updatedStations;
  };

  const calculateTotalRemittance = (managedStations) => {
    // Initialize total remittance
    let totalRemittance = 0;

    // Iterate over the managedStations array and sum up the remittance of each station
    managedStations.forEach((station) => {
      totalRemittance += station.remittance;
    });

    return totalRemittance;
  };

  const addToSupplyList = (
    managedStations,
    id,
    selectedVoucher,
    selectedQuantity
  ) => {
    // Find the index of the item with the specified id
    const index = managedStations.findIndex((station) => station.id === id);

    if (index === -1) {
      // If the station with the specified id is not found, return the original managedStations
      return managedStations;
    }

    // Create a new array with the updated item replacing the old one
    const updatedStation = {
      ...managedStations[index],
      supplyList: [...managedStations[index].supplyList], // Create a copy of the supplyList array
      supply: { ...managedStations[index].supply }, // Make a copy of the supply object
    };

    // Push the new voucher entry into the supplyList
    updatedStation.supplyList.push({
      price: selectedVoucher,
      quantity: selectedQuantity,
      timestamp: Timestamp.now(),
    });

    // Increment the quantity of the selected voucher in the supply object
    const voucherKey = `PHP${selectedVoucher}`;
    if (updatedStation.supply.hasOwnProperty(voucherKey)) {
      updatedStation.supply[voucherKey] += selectedQuantity;
    } else {
      // If the voucher doesn't exist in the supply object, add it
      updatedStation.supply[voucherKey] = selectedQuantity;
    }

    // Create a new array with the updated station and keep other stations intact
    const updatedStations = managedStations.map((station, idx) => {
      if (idx === index) {
        return updatedStation;
      } else {
        return station;
      }
    });

    return updatedStations;
  };

  async function addSupply(station, currentVoucherSupply) {
    try {
      setError(null);
      setStationLoading(station.id);
      setIsAddSupplyShowed(null);
      setSelectedVoucher(null);
      setSelectedQuantity(null);

      const newCurrentVoucherSupply = { ...currentVoucherSupply };
      const selectedVoucherKey = `PHP${selectedVoucher}`;

      if (newCurrentVoucherSupply[selectedVoucherKey]) {
        newCurrentVoucherSupply[selectedVoucherKey] -= selectedQuantity;
      }

      const subAdminRef = doc(db, "AccessCodes", accessCode);
      await updateDoc(subAdminRef, {
        managedStations: addToSupplyList(
          DATA.managedStations,
          station.id,
          selectedVoucher,
          selectedQuantity
        ),
        transactionHistory: arrayUnion({
          transaction: "addsupply",
          id: station.id,
          name: station.name,
          addedSupply: {
            price: selectedVoucher,
            quantity: selectedQuantity,
          },
          timestamp: Timestamp.now(),
        }),
        currentVoucherSupply: newCurrentVoucherSupply,
      });
      setStationLoading(null);
      setErrorAddSupply(false);
    } catch (error) {
      setStationLoading(null);
      setError(station.id);
    }
  }

  function voucherSupplyCheckpoint(
    selectedVoucher,
    selectedQuantity,
    currentVoucherSupply
  ) {
    if (selectedVoucher === 5) {
      if (selectedQuantity <= currentVoucherSupply.PHP5) {
        return true;
      } else {
        return false;
      }
    } else if (selectedVoucher === 10) {
      if (selectedQuantity <= currentVoucherSupply.PHP10) {
        return true;
      } else {
        return false;
      }
    } else if (selectedVoucher === 15) {
      if (selectedQuantity <= currentVoucherSupply.PHP15) {
        return true;
      } else {
        return false;
      }
    } else if (selectedVoucher === 20) {
      if (selectedQuantity <= currentVoucherSupply.PHP20) {
        return true;
      } else {
        return false;
      }
    }
  }

  if (DATA && !isNotificationLoading) {
    if (userRole === "station") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            overflowX: "hidden", // prevent horizontal scrolling
          }}
        >
          <div style={{ padding: 20, width: 300 }}>
            <img
              src={require(`../assets/mainlogo.png`)}
              alt={`logo`}
              style={{
                height: 75,
              }}
            />
            <p style={{ fontSize: 20 }}>
              Hi, <strong style={{ color: "#FFDD83" }}>{DATA.name}</strong>
            </p>

            <div style={{ marginTop: 10 }}>
              <p>
                Station:{" "}
                <strong style={{ color: "#FFDD83" }}>{DATA.station}</strong>
              </p>
              <p>
                Share:{" "}
                <strong style={{ color: "#FFDD83" }}>
                  {DATA.share * 100 + "%"}
                </strong>
              </p>
              <p>
                Electricity Benefit:{" "}
                <strong style={{ color: "#FFDD83" }}>
                  {" "}
                  PHP {DATA.electricity}
                </strong>
              </p>
              <p>
                Unli Users:{" "}
                <strong style={{ color: "#FFDD83" }}>
                  {DATA.unliCodes.length}
                </strong>{" "}
                <button
                  onClick={() => {
                    navigate("/viewunlicodes", { state: DATA.unliCodes });
                  }}
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    backgroundColor: "white",
                    border: "none",
                    borderRadius: 2.5,
                    color: "black",
                    marginLeft: 5,
                    padding: 5,
                  }}
                >
                  <p style={{ fontSize: 10, fontWeight: "bold" }}>
                    View Unli Codes
                  </p>
                </button>
              </p>
              <p>
                Error Codes:{" "}
                <strong style={{ color: "#FFDD83" }}>
                  {DATA.errorCodes.length}
                </strong>{" "}
                <button
                  onClick={() => {
                    navigate("/viewerrorcodes", { state: DATA.errorCodes });
                  }}
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    backgroundColor: "white",
                    border: "none",
                    borderRadius: 2.5,
                    color: "black",
                    marginLeft: 5,
                    padding: 5,
                  }}
                >
                  <p style={{ fontSize: 10, fontWeight: "bold" }}>
                    View Error Codes
                  </p>
                </button>
              </p>
            </div>

            <button
              style={{
                width: "100%",
                backgroundColor: "#EB455F",
                maxWidth: 300,
                marginTop: 10,
                border: "none",
                borderRadius: 3,
                color: "#FCFFE7",
                fontWeight: "bold",
              }}
              onClick={() => {
                navigate("/adderrorcode");
              }}
            >
              <p style={{ margin: 5 }}>Add Error Code</p>
            </button>

            <div style={{ marginTop: 30 }}>
              <h2 style={{ margin: 0, marginBottom: 5, color: "white" }}>
                Supply
              </h2>

              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  overflowX: "auto",
                  maxWidth: 400,
                }}
              >
                <EachVoucher type={5} quantity={DATA.supply.PHP5} />
                <EachVoucher type={10} quantity={DATA.supply.PHP10} />
                <EachVoucher type={15} quantity={DATA.supply.PHP15} />
              </div>

              <button
                style={{
                  width: "100%",
                  backgroundColor: "#EB455F",
                  maxWidth: 400,
                  marginTop: 20,
                  border: "none",
                  borderRadius: 3,
                  color: "#FCFFE7",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  navigate("/requestsupply");
                }}
              >
                <p style={{ margin: 10 }}>Request Supply</p>
              </button>
              <button
                style={{
                  width: "100%",
                  backgroundColor: "#FCFFE7",
                  maxWidth: 400,
                  border: "none",
                  borderRadius: 3,
                  color: "black",
                  fontWeight: "bold",
                  marginTop: 5,
                }}
                onClick={() => {
                  navigate("/viewsupplyrequests");
                }}
              >
                <p style={{ margin: 10 }}>View Supply Requests</p>
              </button>
              <button
                style={{
                  width: "100%",
                  backgroundColor: "#FCFFE7",
                  maxWidth: 400,
                  border: "none",
                  borderRadius: 3,
                  color: "black",
                  fontWeight: "bold",
                  marginTop: 5,
                }}
                onClick={() => {
                  navigate("/viewsupplyhistory", {
                    state: {
                      userRole: "station",
                      stationAccessCode: accessCode,
                    },
                  });
                }}
              >
                <p style={{ margin: 10 }}>View Supply History</p>
              </button>
              <button
                style={{
                  width: "100%",
                  backgroundColor: "#FCFFE7",
                  maxWidth: 400,
                  border: "none",
                  borderRadius: 3,
                  color: "black",
                  fontWeight: "bold",
                  marginTop: 5,
                }}
                onClick={() => {
                  navigate("/viewremithistory", {
                    state: { accessCode: accessCode, userRole: "station" },
                  });
                }}
              >
                <p style={{ margin: 10 }}>View Remit History</p>
              </button>
            </div>
          </div>
        </div>
      );
    } else if (userRole === "admin") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            overflowX: "hidden", // prevent horizontal scrolling
          }}
        >
          <div style={{ padding: 20, width: 300 }}>
            <h2 style={{ textAlign: "center" }}>Welcome Admin Sandz</h2>
            <div
              style={{
                marginTop: 20,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p>Please select a station</p>
              {/* burak */}
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 10,
                }}
              >
                <h2>Burak</h2>
                {DATA.managedStations.map((station) =>
                  station.station === "Burak" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 15,
                      }}
                    >
                      <button
                        onClick={() => {
                          navigate("/viewstation", {
                            state: station.accessCode,
                          });
                        }}
                        style={{
                          backgroundColor: "#EB455F ",
                          padding: 10,
                          color: "white",
                          border: "none",
                          paddingTop: 15,
                          paddingBottom: 15,
                          borderRadius: 10,
                          width: 100,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          {station.name}
                        </p>
                      </button>
                      {notificationDATA[station.accessCode] ? (
                        <div style={{ position: "absolute", zIndex: -1 }}>
                          <p
                            style={{
                              fontSize: 13,
                              // position: "absolute",
                              fontWeight: "bold",
                              marginLeft: 120,
                              backgroundColor: "red",
                              padding: 5,
                              borderRadius: 100,
                              // right: 60,
                            }}
                          >
                            {notificationDATA[station.accessCode]}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  ) : null
                )}
              </div>
              {/* canlapwas */}
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 10,
                }}
              >
                <h2>Canlapwas</h2>
                {DATA.managedStations.map((station) =>
                  station.station === "Canlapwas" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 15,
                      }}
                    >
                      <button
                        onClick={() => {
                          navigate("/viewstation", {
                            state: station.accessCode,
                          });
                        }}
                        style={{
                          backgroundColor: "#EB455F ",
                          padding: 10,
                          color: "white",
                          border: "none",
                          paddingTop: 15,
                          paddingBottom: 15,
                          borderRadius: 10,
                          width: 100,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          {station.name}
                        </p>
                      </button>
                      {notificationDATA[station.accessCode] ? (
                        <div style={{ position: "absolute", zIndex: -1 }}>
                          <p
                            style={{
                              fontSize: 13,
                              // position: "absolute",
                              fontWeight: "bold",
                              marginLeft: 120,
                              backgroundColor: "red",
                              padding: 5,
                              borderRadius: 100,
                              // right: 60,
                            }}
                          >
                            {notificationDATA[station.accessCode]}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  ) : null
                )}
              </div>
              {/* guindapunan */}
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h2>Guindapunan</h2>
                {DATA.managedStations.map((station) =>
                  station.station === "Guindapunan" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 15,
                      }}
                    >
                      <button
                        onClick={() => {
                          navigate("/viewstation", {
                            state: station.accessCode,
                          });
                        }}
                        style={{
                          backgroundColor: "#EB455F ",
                          padding: 10,
                          color: "white",
                          border: "none",
                          paddingTop: 15,
                          paddingBottom: 15,
                          borderRadius: 10,
                          width: 100,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          {station.name}
                        </p>
                      </button>
                      {notificationDATA[station.accessCode] ? (
                        <div style={{ position: "absolute", zIndex: -1 }}>
                          <p
                            style={{
                              fontSize: 13,
                              // position: "absolute",
                              fontWeight: "bold",
                              marginLeft: 120,
                              backgroundColor: "red",
                              padding: 5,
                              borderRadius: 100,
                              // right: 60,
                            }}
                          >
                            {notificationDATA[station.accessCode]}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  ) : null
                )}
              </div>
              {/* igot */}
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h2>Igot</h2>
                {DATA.managedStations.map((station) =>
                  station.station === "Igot" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 15,
                      }}
                    >
                      <button
                        onClick={() => {
                          navigate("/viewstation", {
                            state: station.accessCode,
                          });
                        }}
                        style={{
                          backgroundColor: "#EB455F ",
                          padding: 10,
                          color: "white",
                          border: "none",
                          paddingTop: 15,
                          paddingBottom: 15,
                          borderRadius: 10,
                          width: 100,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          {station.name}
                        </p>
                      </button>
                      {notificationDATA[station.accessCode] ? (
                        <div style={{ position: "absolute", zIndex: -1 }}>
                          <p
                            style={{
                              fontSize: 13,
                              // position: "absolute",
                              fontWeight: "bold",
                              marginLeft: 120,
                              backgroundColor: "red",
                              padding: 5,
                              borderRadius: 100,
                              // right: 60,
                            }}
                          >
                            {notificationDATA[station.accessCode]}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  ) : null
                )}
              </div>
              {/* bono-anan */}
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h2>Bonoanan</h2>
                {DATA.managedStations.map((station) =>
                  station.station === "Bonoanan" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 15,
                      }}
                    >
                      <button
                        onClick={() => {
                          navigate("/viewstation", {
                            state: station.accessCode,
                          });
                        }}
                        style={{
                          backgroundColor: "#EB455F ",
                          padding: 10,
                          color: "white",
                          border: "none",
                          paddingTop: 15,
                          paddingBottom: 15,
                          borderRadius: 10,
                          width: 100,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          {station.name}
                        </p>
                      </button>
                      {notificationDATA[station.accessCode] ? (
                        <div style={{ position: "absolute", zIndex: -1 }}>
                          <p
                            style={{
                              fontSize: 13,
                              // position: "absolute",
                              fontWeight: "bold",
                              marginLeft: 120,
                              backgroundColor: "red",
                              padding: 5,
                              borderRadius: 100,
                              // right: 60,
                            }}
                          >
                            {notificationDATA[station.accessCode]}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  ) : null
                )}
              </div>
              {/* calapog */}
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h2>Calapog</h2>
                {DATA.managedStations.map((station) =>
                  station.station === "Calapog" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 15,
                      }}
                    >
                      <button
                        onClick={() => {
                          navigate("/viewstation", {
                            state: station.accessCode,
                          });
                        }}
                        style={{
                          backgroundColor: "#EB455F ",
                          padding: 10,
                          color: "white",
                          border: "none",
                          paddingTop: 15,
                          paddingBottom: 15,
                          borderRadius: 10,
                          width: 100,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          {station.name}
                        </p>
                      </button>
                      {notificationDATA[station.accessCode] ? (
                        <div style={{ position: "absolute", zIndex: -1 }}>
                          <p
                            style={{
                              fontSize: 13,
                              // position: "absolute",
                              fontWeight: "bold",
                              marginLeft: 120,
                              backgroundColor: "red",
                              padding: 5,
                              borderRadius: 100,
                              // right: 60,
                            }}
                          >
                            {notificationDATA[station.accessCode]}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  ) : null
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (userRole === "subadmin") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            overflowX: "hidden", // prevent horizontal scrolling
          }}
        >
          <div style={{ padding: 20, width: 300 }}>
            <h2 style={{ textAlign: "center" }}>Hi, {DATA.name}</h2>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: "#00b2ca",
                  borderRadius: 10,
                  padding: 10,
                }}
              >
                <p style={{ fontSize: 11 }}>TOTAL REMITTANCE</p>
                <p style={{ fontSize: 25, fontWeight: "bold" }}>
                  {formatPrice(calculateTotalRemittance(DATA.managedStations))}
                </p>
                {/* <p style={{ fontSize: 11 }}>for April 2024</p> */}
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 10,
                  padding: 10,
                  marginTop: 10,
                  color: "black",
                }}
              >
                <p
                  style={{
                    fontSize: 13,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  CURRENT VOUCHER SUPPLY
                </p>
                <div
                  style={{
                    marginTop: 20,
                    display: "flex",
                    overflowX: "auto",
                    maxWidth: 400,
                  }}
                >
                  <EachVoucher2
                    type={5}
                    quantity={DATA.currentVoucherSupply.PHP5}
                  />
                  <EachVoucher2
                    type={10}
                    quantity={DATA.currentVoucherSupply.PHP10}
                  />
                  {DATA.is15 ? (
                    <EachVoucher2
                      type={15}
                      quantity={DATA.currentVoucherSupply.PHP15}
                    />
                  ) : (
                    <EachVoucher2
                      type={20}
                      quantity={DATA.currentVoucherSupply.PHP20}
                    />
                  )}
                </div>
                <button
                  onClick={() => {
                    navigate("/viewsubadminsupplyhistory");
                  }}
                  style={{
                    color: "white",
                    marginTop: 10,
                    flex: 1,
                    width: "100%",
                    marginLeft: 2.5,
                    marginRight: 2.5,
                    backgroundColor: "black",
                    border: "none",
                    borderRadius: 5,
                    padding: 10,
                    fontWeight: "bold",
                  }}
                >
                  <p style={{ fontSize: 12 }}>View Supply History</p>
                </button>
              </div>
              <button
                style={{
                  color: "white",
                  marginTop: 10,
                  flex: 1,
                  width: "100%",
                  marginLeft: 2.5,
                  marginRight: 2.5,
                  backgroundColor: "#EB455F",
                  border: "none",
                  borderRadius: 5,
                  padding: 10,
                  fontWeight: "bold",
                }}
                onClick={() => {
                  navigate("/remitallstations", {
                    state: {
                      allStationsDATA: DATA.managedStations,
                      subAdminName: DATA.name,
                    },
                  });
                }}
              >
                <p style={{ fontSize: 12 }}>Remit All Stations</p>
              </button>
              <button
                style={{
                  color: "black",
                  marginTop: 10,
                  flex: 1,
                  width: "100%",
                  marginLeft: 2.5,
                  marginRight: 2.5,
                  backgroundColor: "white",
                  border: "none",
                  borderRadius: 5,
                  padding: 10,
                  fontWeight: "bold",
                }}
                onClick={() => {
                  navigate("/viewsubadminremithistory", {
                    state: {
                      remitHistory: sortReversedRemitHistory(DATA.remitHistory),
                      subAdminName: DATA.name,
                    },
                  });
                }}
              >
                <p style={{ fontSize: 12 }}>View Remit History</p>
              </button>
              <p
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                Stations
              </p>
              {DATA.managedStations.map((station) => (
                <div
                  style={{
                    backgroundColor: "#fbd1a2",
                    marginTop: 10,
                    borderRadius: 10,
                    padding: 10,
                    color: "black",
                  }}
                >
                  <div
                    style={{ display: "flex", flex: 1, alignItems: "center" }}
                  >
                    <div style={{ flex: 0.8 }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <RiHomeWifiFill size={15} />
                        <p
                          style={{
                            fontSize: 13,
                            marginLeft: 5,
                            fontWeight: "bold",
                          }}
                        >
                          {station.id}
                        </p>
                      </div>

                      <p style={{ fontSize: 13, fontWeight: "bold" }}>
                        {station.name}
                      </p>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p style={{ fontSize: 10, marginBottom: 10 }}> Supply</p>
                      <div style={{ display: "flex" }}>
                        <EachMiniVoucher
                          type={5}
                          quantity={station.supply.PHP5}
                        />
                        <EachMiniVoucher
                          type={10}
                          quantity={station.supply.PHP10}
                        />
                        {DATA.is15 ? (
                          <EachMiniVoucher
                            type={15}
                            quantity={station.supply.PHP15}
                          />
                        ) : (
                          <EachMiniVoucher
                            type={20}
                            quantity={station.supply.PHP20}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        flex: 0.8,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ marginLeft: 10 }}>
                        <p style={{ fontSize: 10 }}> Remittance</p>
                        <p style={{ fontSize: 13, fontWeight: "bold" }}>
                          {formatPrice(station.remittance)}
                        </p>
                      </div>
                      <div style={{ marginTop: 10, marginLeft: 10 }}>
                        <p style={{ fontSize: 10 }}> Share</p>
                        {station.advanceRemit ? (
                          <>
                            <p style={{ fontSize: 8, color: "gray" }}>
                              No share
                            </p>
                            <p style={{ fontSize: 8, color: "gray" }}>
                              (Advance Remit)
                            </p>
                          </>
                        ) : station.shareAmount > 0 ? (
                          <p style={{ fontSize: 13, fontWeight: "bold" }}>
                            {formatPrice(station.shareAmount)}
                          </p>
                        ) : (
                          <p style={{ fontSize: 13, fontWeight: "bold" }}>
                            {formatPrice(0)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  {stationLoading === station.id ? (
                    // show loading
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 15,
                      }}
                    >
                      <ReactLoading
                        type={"spin"}
                        color={"black"}
                        height={20}
                        width={20}
                      />
                    </div>
                  ) : isRemitShowed || isAddSupplyShowed ? (
                    isRemitShowed === station.id ? (
                      // show remit
                      <div style={{ marginTop: 10 }}>
                        <button
                          onClick={() => {
                            setIsRemitShowed(null);
                            setTotalPrice(0);
                            setCheckedItems({});
                          }}
                          style={{
                            border: "none",
                            backgroundColor: "#fbd1a2",
                          }}
                        >
                          <RiCloseFill size={20} color={"black"} />
                        </button>
                        <p
                          style={{
                            fontSize: 11,
                            fontWeight: "bold",
                            textAlign: "center",
                            marginBottom: 10,
                          }}
                        >
                          Remittances from {station.name}
                        </p>
                        <div
                          style={{
                            flex: 1,
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          {station.supplyList.length > 0 ? (
                            station.supplyList.map((supply, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: 5,
                                }}
                              >
                                <input
                                  type="checkbox"
                                  id={`supply_${index}`}
                                  name={`supply_${index}`}
                                  checked={checkedItems[index] || false}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      index,
                                      supply.price,
                                      supply.quantity,
                                      station.advanceRemit,
                                      station.share
                                    )
                                  }
                                />
                                <label htmlFor={`supply_${index}`}>
                                  <EachMiniVoucher2
                                    price={supply.price}
                                    quantity={supply.quantity}
                                    timestamp={supply.timestamp}
                                  />
                                </label>
                              </div>
                            ))
                          ) : (
                            <div>
                              <p
                                style={{
                                  textAlign: "center",
                                  fontSize: 13,
                                  color: "gray",
                                }}
                              >
                                No supply
                              </p>
                            </div>
                          )}

                          {totalPrice > 0 ? (
                            <>
                              <div>
                                <p
                                  style={{
                                    fontSize: 13,
                                    textAlign: "center",
                                  }}
                                >
                                  Total Remit
                                </p>
                                <p
                                  style={{
                                    fontSize: 15,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  PHP {totalPrice}.00
                                </p>
                              </div>
                              <button
                                onClick={async () => {
                                  const supplyListCopy = [
                                    ...station.supplyList,
                                  ];

                                  const newSupplyList = getNewSupplyList(
                                    {
                                      ...station,
                                      supplyList: supplyListCopy,
                                    },
                                    checkedItems
                                  );
                                  const remittedSupplyList =
                                    getRemittedSupplyList(
                                      {
                                        ...station,
                                        supplyList: supplyListCopy,
                                      },
                                      checkedItems
                                    );
                                  console.log(newSupplyList);
                                  console.log(remittedSupplyList);
                                  setIsRemitShowed(null);
                                  setCheckedItems({});
                                  setError(null);
                                  setTotalPrice(0);
                                  setStationLoading(station.id);
                                  await updateDatabase(
                                    station,
                                    remittedSupplyList,
                                    newSupplyList
                                  );
                                  setStationLoading(null);
                                }}
                                style={{
                                  color: "white",
                                  marginTop: 10,
                                  flex: 1,
                                  width: "100%",
                                  marginLeft: 2.5,
                                  marginRight: 2.5,
                                  backgroundColor: "#EB455F",
                                  border: "none",
                                  fontSize: 10,
                                  borderRadius: 5,
                                  padding: 10,
                                  fontWeight: "bold",
                                }}
                              >
                                Remit
                              </button>
                            </>
                          ) : null}
                        </div>
                      </div>
                    ) : isAddSupplyShowed === station.id ? (
                      // show add supply
                      <div style={{ marginTop: 10 }}>
                        <button
                          onClick={() => {
                            setErrorAddSupply(false);
                            setIsAddSupplyShowed(null);
                            setSelectedVoucher(null);
                            setSelectedQuantity(null);
                          }}
                          style={{
                            border: "none",
                            backgroundColor: "#fbd1a2",
                          }}
                        >
                          <RiCloseFill size={20} color={"black"} />
                        </button>
                        <p
                          style={{
                            fontSize: 11,
                            fontWeight: "bold",
                            textAlign: "center",
                            marginBottom: 10,
                          }}
                        >
                          Add supply
                        </p>
                        <p
                          style={{
                            fontSize: 11,
                            color: "black",
                            textAlign: "center",
                            marginBottom: 5,
                          }}
                        >
                          Select voucher price
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ display: "flex", flex: 0.7 }}>
                            <div
                              style={{
                                textAlign: "center",
                                marginRight: "5px",
                                flex: 1,
                              }}
                              onClick={() => {
                                setSelectedVoucher(5);
                              }}
                            >
                              <img
                                src={require(`../assets/PHP${5}.png`)}
                                alt={`PHP${5}`}
                                style={{
                                  width: "100%",
                                  objectFit: "contain",
                                  borderRadius: 3,
                                  opacity: selectedVoucher === 5 ? 1 : 0.5,
                                }}
                              />
                              {selectedVoucher === 5 ? (
                                <p
                                  style={{
                                    fontSize: 8,
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  PHP5 Selected
                                </p>
                              ) : null}
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                marginRight: "5px",
                                flex: 1,
                              }}
                              onClick={() => {
                                setSelectedVoucher(10);
                              }}
                            >
                              <img
                                src={require(`../assets/PHP${10}.png`)}
                                alt={`PHP${10}`}
                                style={{
                                  width: "100%",
                                  objectFit: "contain",
                                  borderRadius: 3,
                                  opacity: selectedVoucher === 10 ? 1 : 0.5,
                                }}
                              />
                              {selectedVoucher === 10 ? (
                                <p
                                  style={{
                                    fontSize: 8,
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  PHP10 Selected
                                </p>
                              ) : null}
                            </div>
                            {DATA.is15 ? (
                              <div
                                style={{
                                  textAlign: "center",
                                  marginRight: "5px",
                                  flex: 1,
                                }}
                                onClick={() => {
                                  setSelectedVoucher(15);
                                }}
                              >
                                <img
                                  src={require(`../assets/PHP${15}.png`)}
                                  alt={`PHP${15}`}
                                  style={{
                                    width: "100%",
                                    objectFit: "contain",
                                    borderRadius: 3,
                                    opacity: selectedVoucher === 15 ? 1 : 0.5,
                                  }}
                                />
                                {selectedVoucher === 15 ? (
                                  <p
                                    style={{
                                      fontSize: 8,
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    PHP15 Selected
                                  </p>
                                ) : null}
                              </div>
                            ) : (
                              <div
                                style={{
                                  textAlign: "center",
                                  marginRight: "5px",
                                  flex: 1,
                                }}
                                onClick={() => {
                                  setSelectedVoucher(20);
                                }}
                              >
                                <img
                                  src={require(`../assets/PHP${20}.png`)}
                                  alt={`PHP${20}`}
                                  style={{
                                    width: "100%",
                                    objectFit: "contain",
                                    borderRadius: 3,
                                    opacity: selectedVoucher === 20 ? 1 : 0.5,
                                  }}
                                />
                                {selectedVoucher === 20 ? (
                                  <p
                                    style={{
                                      fontSize: 8,
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    PHP20 Selected
                                  </p>
                                ) : null}
                              </div>
                            )}
                          </div>
                        </div>
                        <p
                          style={{
                            fontSize: 11,
                            color: "black",
                            textAlign: "center",
                            marginBottom: 5,
                            marginTop: 15,
                          }}
                        >
                          Enter quantity
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ display: "flex", flex: 0.7 }}>
                            <div
                              style={{
                                textAlign: "center",
                                marginRight: "5px",
                                flex: 1,
                              }}
                              onClick={() => {
                                setSelectedQuantity(50);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor:
                                    selectedQuantity === 50 ? "black" : "white",
                                  color:
                                    selectedQuantity === 50 ? "white" : "black",
                                  flex: 1,
                                  height: 60,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  objectFit: "contain",
                                  borderRadius: 3,
                                }}
                              >
                                <p style={{ fontSize: 13, fontWeight: "bold" }}>
                                  50 PCS
                                </p>
                              </div>
                              {selectedQuantity === 50 ? (
                                <p
                                  style={{
                                    fontSize: 9,
                                    color: "black",
                                    fontWeight: "bold",
                                    marginTop: 2.5,
                                  }}
                                >
                                  Selected
                                </p>
                              ) : null}
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                marginRight: "5px",
                                flex: 1,
                              }}
                              onClick={() => {
                                setSelectedQuantity(100);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor:
                                    selectedQuantity === 100
                                      ? "black"
                                      : "white",
                                  color:
                                    selectedQuantity === 100
                                      ? "white"
                                      : "black",
                                  flex: 1,
                                  height: 60,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  objectFit: "contain",
                                  borderRadius: 3,
                                }}
                              >
                                <p style={{ fontSize: 13, fontWeight: "bold" }}>
                                  100 PCS
                                </p>
                              </div>
                              {selectedQuantity === 100 ? (
                                <p
                                  style={{
                                    fontSize: 9,
                                    color: "black",
                                    fontWeight: "bold",
                                    marginTop: 2.5,
                                  }}
                                >
                                  Selected
                                </p>
                              ) : null}
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                marginRight: "5px",
                                flex: 1,
                              }}
                              onClick={() => {
                                setSelectedQuantity(200);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor:
                                    selectedQuantity === 200
                                      ? "black"
                                      : "white",
                                  color:
                                    selectedQuantity === 200
                                      ? "white"
                                      : "black",
                                  flex: 1,
                                  height: 60,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  objectFit: "contain",
                                  borderRadius: 3,
                                }}
                              >
                                <p style={{ fontSize: 13, fontWeight: "bold" }}>
                                  200 PCS
                                </p>
                              </div>
                              {selectedQuantity === 200 ? (
                                <p
                                  style={{
                                    fontSize: 9,
                                    marginTop: 2.5,
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Selected
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        {selectedVoucher && selectedQuantity ? (
                          <div>
                            <p
                              style={{
                                fontSize: 11,
                                textAlign: "center",
                                marginTop: 10,
                              }}
                            >
                              Summary
                            </p>
                            <p
                              style={{
                                fontSize: 11,
                                fontWeight: "bold",
                                fontStyle: "italic",
                                textAlign: "center",
                                marginTop: 5,
                              }}
                            >
                              Add {selectedQuantity}PCS of PHP{selectedVoucher}{" "}
                              voucher
                            </p>
                          </div>
                        ) : null}
                        {selectedVoucher && selectedQuantity ? (
                          <>
                            <button
                              style={{
                                color: "white",
                                marginTop: 10,
                                flex: 1,
                                width: "100%",
                                marginLeft: 2.5,
                                marginRight: 2.5,
                                backgroundColor: "#EB455F",
                                border: "none",
                                fontSize: 10,
                                borderRadius: 5,
                                padding: 10,
                                fontWeight: "bold",
                              }}
                              onClick={async () => {
                                if (
                                  voucherSupplyCheckpoint(
                                    selectedVoucher,
                                    selectedQuantity,
                                    DATA.currentVoucherSupply
                                  )
                                ) {
                                  await addSupply(
                                    station,
                                    DATA.currentVoucherSupply
                                  );
                                } else {
                                  setErrorAddSupply(true);
                                }
                              }}
                            >
                              Add Supply
                            </button>
                            {errorAddSupply ? (
                              <div>
                                <p
                                  style={{
                                    fontSize: 10,
                                    color: "red",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    marginTop: 5,
                                  }}
                                >
                                  Error! Current voucher supply is insufficient.
                                </p>
                              </div>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                    ) : (
                      // show buttons
                      <div style={{ marginTop: 10, display: "flex" }}>
                        <EachButton
                          onClickFunction={() => {
                            setIsAddSupplyShowed(null);
                            setTotalPrice(0);
                            setCheckedItems({});
                            setIsRemitShowed(station.id);
                          }}
                          text={"Remit"}
                        />
                        <EachButton
                          onClickFunction={() => {
                            setIsRemitShowed(null);
                            setSelectedVoucher(null);
                            setSelectedQuantity(null);
                            setIsAddSupplyShowed(station.id);
                          }}
                          text={"Add Supply"}
                        />
                        <EachButton
                          onClickFunction={() => {
                            const filteredHistory =
                              filterAndSortTransactionHistory(
                                DATA.transactionHistory,
                                station
                              );
                            navigate("/viewtransactionhistory", {
                              state: {
                                transactionHistoryDATA: filteredHistory,
                                stationDATA: station,
                              },
                            });
                          }}
                          text={"Transaction History"}
                        />
                      </div>
                    )
                  ) : (
                    // show buttons
                    <div style={{ marginTop: 10, display: "flex" }}>
                      <EachButton
                        onClickFunction={() => {
                          setIsAddSupplyShowed(null);
                          setTotalPrice(0);
                          setCheckedItems({});
                          setIsRemitShowed(station.id);
                        }}
                        text={"Remit"}
                      />
                      <EachButton
                        onClickFunction={() => {
                          setIsRemitShowed(null);
                          setSelectedVoucher(null);
                          setSelectedQuantity(null);
                          setIsAddSupplyShowed(station.id);
                        }}
                        text={"Add Supply"}
                      />
                      <EachButton
                        onClickFunction={() => {
                          const filteredHistory =
                            filterAndSortTransactionHistory(
                              DATA.transactionHistory,
                              station
                            );
                          navigate("/viewtransactionhistory", {
                            state: {
                              transactionHistoryDATA: filteredHistory,
                              stationDATA: station,
                            },
                          });
                        }}
                        text={"Transaction History"}
                      />
                    </div>
                  )}
                  {error === station.id ? (
                    <p
                      style={{
                        fontSize: 11,
                        color: "red",
                        fontWeight: "bold",
                        textAlign: "center",
                        marginTop: 5,
                      }}
                    >
                      Error. Please try again
                    </p>
                  ) : null}
                  {/* {station.shareAmount > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 10,
                        backgroundColor: "white",
                        padding: 10,
                        borderRadius: 10,
                      }}
                    >
                      <div
                        style={{
                          marginRight: 20,
                        }}
                      >
                        <p style={{ fontSize: 12 }}>
                          Give share of remittance to {station.name}
                        </p>
                        <p style={{ fontSize: 13, fontWeight: "bold" }}>
                          {formatPrice(station.shareAmount)}
                        </p>
                      </div>
                      <button
                        style={{
                          color: "white",
                          flex: 1,
                          backgroundColor: "#EB455F",
                          border: "none",
                          borderRadius: 5,
                          padding: 10,
                          fontWeight: "bold",
                        }}
                        onClick={async () => {
                          const subAdminRef = doc(
                            db,
                            "AccessCodes",
                            accessCode
                          );
                          await updateDoc(subAdminRef, {
                            managedStations: updateShareAmountToNull(station),
                          });
                        }}
                      >
                        <p style={{ fontSize: 12 }}>Done</p>
                      </button>
                    </div>
                  ) : null} */}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    } else if (userRole === "master") {
    } else return null;
  } else {
    return <LoadingScreen />;
  }
};

export default Dashboard;
