import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { UserAuth } from "../context/AuthContext";
import { FaArrowLeft } from "react-icons/fa";
import GoBack from "../components/GoBack";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
} from "firebase/firestore";
import LoadingScreen from "./LoadingScreen";

const AddErrorCode = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accessCode } = UserAuth();
  const [voucherPrice, setVoucherPrice] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const EachMiniVoucher = ({ type, selected }) => {
    return (
      <div
        style={{
          textAlign: "center",
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          margin: 5,
        }}
      >
        <button
          style={{ backgroundColor: "#083780", border: "none" }}
          onClick={() => {
            if (type === 5) {
              setVoucherPrice(5);
            } else if (type === 10) {
              setVoucherPrice(10);
            } else if (type === 15) {
              setVoucherPrice(15);
            }
          }}
        >
          <img
            src={require(`../assets/PHP${type}.png`)}
            alt={`PHP${type}`}
            style={{
              opacity: selected ? 1 : 0.5,
              width: "100%",
              objectFit: "contain",
              borderRadius: 10,
            }}
          />
          <p
            style={{
              fontWeight: "bold",
              fontSize: 18,
              color: "white",
              marginLeft: 5,
            }}
          >
            {selected ? "Selected" : null}
          </p>
        </button>
      </div>
    );
  };

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <div
        style={{
          backgroundColor: "#083780",
          color: "white",
          margin: "0 auto",
          padding: "20px",
          overflowX: "hidden",
          width: 300,
        }}
      >
        <GoBack
          onClickFunction={() => {
            navigate("../dashboard", { replace: true });
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 30,
          }}
        >
          <h2 style={{ margin: 0, marginBottom: 30 }}>Add Error Code</h2>
          <p style={{ fontSize: 15, marginBottom: 10 }}>Select voucher price</p>
          <div style={{ display: "flex" }}>
            <EachMiniVoucher type={5} selected={voucherPrice === 5} />
            <EachMiniVoucher type={10} selected={voucherPrice === 10} />
            <EachMiniVoucher type={15} selected={voucherPrice === 15} />
          </div>
          <div style={{ marginTop: 20 }}>
            <p style={{ textAlign: "center", marginBottom: 10 }}>
              Enter the voucher code
            </p>
            <input
              type="text"
              value={errorCode}
              onChange={(e) => {
                const inputValue = e.target.value.toUpperCase();
                // Check if the input contains at least one non-space character
                setErrorCode(inputValue);
              }}
              maxLength={8}
              placeholder={"Code"}
              style={{
                textAlign: "center",
                padding: 10,
                fontSize: 18,
                border: "none",
                //   textTransform: "uppercase",
                borderRadius: 10,
              }}
            />
          </div>
          <button
            disabled={!voucherPrice || !errorCode}
            onClick={async () => {
              setIsLoading(true);
              const errorCodeRef = doc(db, "AccessCodes", accessCode);
              await updateDoc(errorCodeRef, {
                errorCodes: arrayUnion({
                  voucherCode: errorCode,
                  voucherPrice: voucherPrice,
                }),
              });
              navigate("../dashboard", { replace: true });
            }}
            style={{
              width: "100%",
              backgroundColor:
                !voucherPrice || !errorCode || errorCode.length !== 8
                  ? "#ccc"
                  : "#EB455F",
              maxWidth: 400,
              marginTop: 50,
              border: "none",
              borderRadius: 3,
              color: "#FCFFE7",
              fontWeight: "bold",
            }}
          >
            <p style={{ margin: 10 }}>Add Error Code</p>
          </button>
        </div>
      </div>
    );
  }
};

export default AddErrorCode;
