import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import GoBack from "../components/GoBack";
import { db } from "../firebase";
import LoadingScreen from "./LoadingScreen";
import { collection, getDocs, where, query, orderBy } from "firebase/firestore";
import { RiHomeWifiFill } from "react-icons/ri";

const ViewTransactionHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stationDATA = location.state.stationDATA;
  const transactionHistoryDATA = location.state.transactionHistoryDATA;
  const [isLoading, setIsLoading] = useState(false);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const formatDate2 = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const EachMiniSingleVoucher = ({ price, quantity }) => {
    return (
      <div
        style={{
          textAlign: "center",
          flex: 1,
          marginTop: 10,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={require(`../assets/PHP${price}.png`)}
          alt={`PHP${price}`}
          style={{
            width: "12.5%",
            objectFit: "contain",
            borderRadius: 3,
            marginRight: 5,
          }}
        />
        <p style={{ fontWeight: "500", fontSize: 13, marginRight: 5 }}>
          {quantity}
        </p>
      </div>
    );
  };

  const EachMiniVoucher2 = ({ price, quantity, timestamp }) => {
    return (
      <div
        style={{
          textAlign: "center",
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: 2.5,
        }}
      >
        <img
          src={require(`../assets/PHP${price}.png`)}
          alt={`PHP${price}`}
          style={{
            width: "10%",
            objectFit: "contain",
            borderRadius: 3,
            marginRight: 5,
          }}
        />
        <p style={{ fontWeight: "500", fontSize: 13, marginRight: 5 }}>
          {quantity}
        </p>
        <p
          style={{
            fontWeight: "500",
            fontSize: 8,
            marginRight: 5,
            color: "gray",
          }}
        >
          Supply from {formatDate2(timestamp)}
        </p>
      </div>
    );
  };

  const formatPrice = (amount) => {
    // Function to format number to Philippine peso currency
    const formatToPhilippineCurrency = (value) => {
      // Convert value to a string and split it into whole and decimal parts
      const [wholePart, decimalPart] = value.toString().split(".");

      // Format whole part with comma separator every 3 digits
      const formattedWholePart = wholePart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      // If there's a decimal part, add it with 2 decimal places; otherwise, add .00
      const formattedDecimalPart = decimalPart
        ? `.${decimalPart.padEnd(2, "0")}`
        : ".00";

      // Return the formatted currency string with the Philippine peso symbol
      return `₱${formattedWholePart}${formattedDecimalPart}`;
    };

    // Call the formatting function and return the result
    return formatToPhilippineCurrency(amount);
  };

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <div
        style={{
          backgroundColor: "#083780",
          color: "white",
          margin: "0 auto",
          padding: "20px",
          overflowX: "hidden",
          width: 300,
        }}
      >
        <GoBack
          onClickFunction={() => {
            navigate("../dashboard", {
              replace: true,
            });
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2 style={{ margin: 0, marginBottom: 10 }}>Transaction History</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <RiHomeWifiFill size={25} />
            <p style={{ fontWeight: "bold", marginLeft: 5 }}>
              {stationDATA.name}
            </p>
          </div>
          {transactionHistoryDATA.length > 0 ? (
            transactionHistoryDATA.map((transaction) => {
              if (transaction.transaction === "addsupply") {
                return (
                  <div
                    style={{
                      margin: 5,
                      backgroundColor: "white",
                      color: "black",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      width: "80%",
                      borderRadius: 7.5,
                      padding: 10,
                    }}
                  >
                    <p style={{ fontSize: 13, fontWeight: "bold" }}>
                      Add Supply
                    </p>
                    <p style={{ fontSize: 11 }}>
                      {formatDate(transaction.timestamp)}
                    </p>
                    <EachMiniSingleVoucher
                      price={transaction.addedSupply.price}
                      quantity={transaction.addedSupply.quantity}
                    />
                  </div>
                );
              } else if (transaction.transaction === "remit") {
                return (
                  <div
                    style={{
                      margin: 5,
                      backgroundColor: "#FFDD83",
                      color: "black",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      width: "80%",
                      borderRadius: 7.5,
                      padding: 10,
                    }}
                  >
                    <p style={{ fontSize: 13, fontWeight: "bold" }}>Remit</p>
                    <p style={{ fontSize: 11, marginBottom: 10 }}>
                      {formatDate(transaction.timestamp)}
                    </p>
                    {transaction.remittance.map((remit) => {
                      return (
                        <EachMiniVoucher2
                          price={remit.price}
                          quantity={remit.quantity}
                          timestamp={remit.timestamp}
                        />
                      );
                    })}
                    <p style={{ fontSize: 11, marginTop: 10 }}>Total amount</p>
                    <p style={{ fontSize: 13, fontWeight: "bold" }}>
                      {formatPrice(transaction.amount)}
                    </p>
                  </div>
                );
              } else {
                return null;
              }
            })
          ) : (
            <div>
              <p style={{ fontSize: 11, color: "lightgray", marginTop: 20 }}>
                Empty record
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default ViewTransactionHistory;
