import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { MdDelete } from "react-icons/md";
import GoBack from "../components/GoBack";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase";
import { collection, getDocs, where, query, orderBy } from "firebase/firestore";
import LoadingScreen from "./LoadingScreen";
import { FaPlus } from "react-icons/fa";

const ViewSupplyHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = location.state.userRole;
  const accessCode = location.state.stationAccessCode;
  const [isLoading, setIsLoading] = useState(false);
  const [supplyHistoryDATA, setSupplyHistoryDATA] = useState([]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const EachMiniVoucher = ({ type, quantity }) => {
    return (
      <div
        style={{
          textAlign: "center",
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          margin: 5,
        }}
      >
        <img
          src={require(`../assets/PHP${type}.png`)}
          alt={`PHP${type}`}
          style={{
            width: "35%",
            objectFit: "contain",
            borderRadius: 10,
          }}
        />
        <p
          style={{
            fontWeight: "bold",
            fontSize: 15,
            color: "black",
            marginLeft: 5,
          }}
        >
          {quantity}
        </p>
      </div>
    );
  };

  useEffect(() => {
    if (accessCode) {
      const fetchSupplyDATA = async () => {
        try {
          setIsLoading(true);
          const supplyHistoryCollectionRef = collection(db, "SupplyHistory");
          const querySnapshot = await getDocs(
            query(
              supplyHistoryCollectionRef,
              where("accessCode", "==", accessCode),
              orderBy("timestamp", "desc")
            )
          );

          if (querySnapshot.empty) {
            setSupplyHistoryDATA(null);
          } else {
            setSupplyHistoryDATA(querySnapshot.docs);
          }

          setIsLoading(false);
        } catch (error) {
          console.log(error.message);
        }
      };

      fetchSupplyDATA();
    }
  }, [accessCode]);

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <div
        style={{
          backgroundColor: "#083780",
          color: "white",
          margin: "0 auto",
          padding: "20px",
          overflowX: "hidden",
          width: 300,
        }}
      >
        <GoBack
          onClickFunction={() => {
            if (userRole === "station") {
              navigate("../dashboard", { replace: true });
            } else if (userRole === "admin") {
              navigate("../viewstation", { replace: true, state: accessCode });
            }
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2 style={{ margin: 0, marginBottom: 10 }}>Supply History</h2>
          {supplyHistoryDATA ? (
            supplyHistoryDATA.map((data) => {
              const timestamp = data.data().timestamp;
              const addedSupply = data.data().addedSupply;
              const oldSupply = data.data().oldSupply;
              const newSupply = data.data().newSupply;
              const source = data.data().source;
              return (
                <div
                  style={{
                    marginTop: 20,
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: 20,
                    width: 250,
                  }}
                >
                  <p
                    style={{
                      fontSize: 13,
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                      paddingTop: 15,
                      paddingLeft: 15,
                      paddingRight: 15,
                    }}
                  >
                    {formatDate(timestamp)}
                  </p>
                  <p
                    style={{
                      fontSize: 11,
                      color: "gray",
                      textAlign: "center",
                      margin: 0,
                      marginBottom: 10,
                    }}
                  >
                    {source === "request"
                      ? "Made through request"
                      : "Made after remit"}
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      margin: 0,
                      fontSize: 12,
                      fontWeight: "600",
                    }}
                  >
                    Old Supply
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      marginBottom: 10,
                    }}
                  >
                    <div style={{ flex: 1, display: "flex", maxWidth: 150 }}>
                      <EachMiniVoucher type={5} quantity={oldSupply.PHP5} />
                      <EachMiniVoucher type={10} quantity={oldSupply.PHP10} />
                      <EachMiniVoucher type={15} quantity={oldSupply.PHP15} />
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#FFDD83",
                      paddingLeft: 15,
                      paddingRight: 15,
                      paddingTop: 10,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        margin: 0,
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "gray",
                      }}
                    >
                      SUPPLY ADDED
                    </p>
                    <div style={{ flex: 1, display: "flex", marginBottom: 10 }}>
                      <EachMiniVoucher type={5} quantity={addedSupply.PHP5} />
                      <EachMiniVoucher type={10} quantity={addedSupply.PHP10} />
                      <EachMiniVoucher type={15} quantity={addedSupply.PHP15} />
                    </div>
                  </div>

                  <p
                    style={{
                      textAlign: "center",
                      margin: 0,
                      fontSize: 12,
                      fontWeight: "600",
                      marginTop: 10,
                    }}
                  >
                    New Supply
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      paddingBottom: 15,
                    }}
                  >
                    <div style={{ flex: 1, display: "flex", maxWidth: 150 }}>
                      <EachMiniVoucher type={5} quantity={newSupply.PHP5} />
                      <EachMiniVoucher type={10} quantity={newSupply.PHP10} />
                      <EachMiniVoucher type={15} quantity={newSupply.PHP15} />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p style={{ fontSize: 13, color: "lightgray" }}>
              No supply history
            </p>
          )}
        </div>
      </div>
    );
  }
};

export default ViewSupplyHistory;
