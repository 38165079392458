import React, { useState, useEffect, useRef } from "react";
import { UserAuth } from "../context/AuthContext";
import { useNavigate, useLocation } from "react-router";
import GoBack from "../components/GoBack";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
} from "firebase/firestore";
import { db } from "../firebase";
import LoadingScreen from "./LoadingScreen";

const MasterSuccessfulAddStation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userRole } = UserAuth();
  const newStationAccessCode = location.state.toString();

  useEffect(() => {
    if (userRole !== "master") {
      navigate("/", { replace: true, state: {} });
    }
  }, [userRole]);

  return (
    <div
      style={{
        backgroundColor: "#083780",
        color: "white",
        margin: "0 auto",
        padding: "20px",
        overflowX: "hidden",
        width: 300,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2 style={{ margin: 0, marginBottom: 5 }}>Success</h2>
        <p style={{ fontSize: 13 }}>Station was successfully added</p>
        <div style={{ marginTop: 50, marginBottom: 25 }}>
          <p>The access code of the new station is</p>
          <h1 style={{ margin: 0, textAlign: "center" }}>
            {newStationAccessCode}
          </h1>
        </div>
        <button
          onClick={async () => {
            navigate("/masterdashboard", { replace: true });
          }}
          style={{
            width: "100%",
            backgroundColor: "#EB455F",
            maxWidth: 400,
            marginTop: 20,
            border: "none",
            borderRadius: 3,
            color: "white",
            fontWeight: "bold",
          }}
        >
          <p style={{ margin: 10 }}>Confirm</p>
        </button>
      </div>
    </div>
  );
};

export default MasterSuccessfulAddStation;
