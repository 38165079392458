import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { MdDelete } from "react-icons/md";
import GoBack from "../components/GoBack";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase";
import { doc, updateDoc, arrayRemove } from "firebase/firestore";
import LoadingScreen from "./LoadingScreen";

const ViewErrorCodes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorCodes, setErrorCodes] = useState(location.state);
  const [isLoading, setIsLoading] = useState(false);
  const { accessCode } = UserAuth();

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <div
        style={{
          backgroundColor: "#083780",
          color: "white",
          margin: "0 auto",
          padding: "20px",
          overflowX: "hidden",
          width: 300,
        }}
      >
        <GoBack
          onClickFunction={() => {
            navigate("../dashboard", { replace: true });
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 30,
          }}
        >
          <h2 style={{ margin: 0, marginBottom: 30 }}>Error Codes</h2>
          {errorCodes.length > 0 ? (
            errorCodes.map((errorCode) => {
              return (
                <div
                  style={{
                    display: "flex",
                    marginBottom: 20,
                    alignItems: "center",
                  }}
                >
                  <img
                    src={require(`../assets/PHP${errorCode.voucherPrice}.png`)}
                    alt={`PHP${errorCode.voucherPrice}`}
                    style={{
                      width: "20%",
                      marginRight: 10,
                      borderRadius: 8,
                    }}
                  />
                  <p style={{ fontSize: 18, fontWeight: "600" }}>
                    {errorCode.voucherCode}
                  </p>
                  <button
                    onClick={async () => {
                      setIsLoading(true);
                      const errorCodeRef = doc(db, "AccessCodes", accessCode);
                      await updateDoc(errorCodeRef, {
                        errorCodes: arrayRemove({
                          voucherCode: errorCode.voucherCode,
                          voucherPrice: errorCode.voucherPrice,
                        }),
                      });
                      const updatedErrorCodes = errorCodes.filter(
                        (code) => code.voucherCode !== errorCode.voucherCode
                      );
                      setErrorCodes(updatedErrorCodes);
                      setIsLoading(false);
                    }}
                    style={{
                      backgroundColor: "#083780",
                      border: "none",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 50,
                    }}
                  >
                    <MdDelete color={"#f75c5c"} size={20} />
                    <p style={{ color: "#d19b9b" }}>Delete</p>
                  </button>
                </div>
              );
            })
          ) : (
            <p style={{ fontSize: 13, fontWeight: "400", color: "#ccc" }}>
              No error codes
            </p>
          )}
        </div>
      </div>
    );
  }
};

export default ViewErrorCodes;
