import { useContext, createContext, useEffect, useState } from "react";
import { signInAnonymously, signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAccessCodeValid, setAccessCodeValid] = useState(false);
  const [accessCode, setTheAccessCode] = useState(null);
  const [userRole, setTheUserRole] = useState(null);

  const anonymousSignIn = async () => {
    await signInAnonymously(auth);
  };

  const logOut = () => {
    signOut(auth);
    setUser(null);
  };

  const setIsAccessCodeValid = (value) => {
    setAccessCodeValid(value);
  };

  const setAccessCode = (value) => {
    setTheAccessCode(value);
  };

  const setUserRole = (value) => {
    setTheUserRole(value);
  };

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAccessCodeValid,
        setIsAccessCodeValid,
        accessCode,
        setAccessCode,
        anonymousSignIn,
        logOut,
        user,
        userRole,
        setUserRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
