import React from "react";
import { Navigate } from "react-router";
import { UserAuth } from "../context/AuthContext";

function Protected({ children }) {
  const { user, isAccessCodeValid } = UserAuth();

  if (!user && !isAccessCodeValid) {
    return <Navigate to="/" />;
  } else {
    return children;
  }
}

export default Protected;
