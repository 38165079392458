import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import GoBack from "../components/GoBack";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
  writeBatch,
  increment,
  FieldValue,
} from "firebase/firestore";
import { db } from "../firebase";
import LoadingScreen from "./LoadingScreen";

const MasterAddSupply = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessCode = location.state;
  const [addPHP5, setAddPHP5] = useState(null);
  const [addPHP10, setAddPHP10] = useState(null);
  const [addPHP20, setAddPHP20] = useState(null);
  const [isAddSupplyLoading, setIsAddSupplyLoading] = useState(false);
  const [error, setError] = useState(false);

  if (isAddSupplyLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <div
        style={{
          backgroundColor: "#083780",
          color: "white",
          margin: "0 auto",
          padding: "20px",
          overflowX: "hidden",
          width: 300,
        }}
      >
        <GoBack
          onClickFunction={() => {
            navigate("/masterdashboard", {
              replace: true,
            });
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 30,
          }}
        >
          <h2 style={{ margin: 0, marginBottom: 10 }}>Add Supply</h2>
          {error ? (
            <p
              style={{
                marginBottom: 20,
                fontSize: 13,
                color: "red",
                fontWeight: "600",
              }}
            >
              Error adding supply! Please try again.
            </p>
          ) : null}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <img
              src={require(`../assets/PHP5.png`)}
              alt={`PHP5`}
              style={{
                width: "35%",
                marginRight: 10,
                borderRadius: 8,
              }}
            />
            <input
              placeholder="Quantity"
              value={addPHP5}
              onChange={(event) => {
                setAddPHP5(event.target.value);
              }}
              type="number"
              style={{
                backgroundColor: "white",
                maxWidth: 80,
                borderRadius: 8,
                textAlign: "center",
                fontSize: 15,
                fontWeight: "600",
                height: 30,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <img
              src={require(`../assets/PHP10.png`)}
              alt={`PHP10`}
              style={{
                width: "35%",
                borderRadius: 8,
                marginRight: 10,
              }}
            />
            <input
              placeholder="Quantity"
              value={addPHP10}
              onChange={(event) => {
                setAddPHP10(event.target.value);
              }}
              type="number"
              style={{
                backgroundColor: "white",
                maxWidth: 80,
                borderRadius: 8,
                textAlign: "center",
                fontSize: 15,
                fontWeight: "600",
                height: 30,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <img
              src={require(`../assets/PHP15.png`)}
              alt={`PHP20`}
              style={{
                width: "35%",
                borderRadius: 10,
                marginRight: 10,
              }}
            />
            <input
              placeholder="Quantity"
              value={addPHP20}
              onChange={(event) => {
                setAddPHP20(event.target.value);
              }}
              type="number"
              style={{
                backgroundColor: "white",
                maxWidth: 80,
                borderRadius: 8,
                textAlign: "center",
                fontSize: 15,
                fontWeight: "600",
                height: 30,
              }}
            />
          </div>

          <button
            onClick={async () => {
              try {
                setIsAddSupplyLoading(true);
                const supplyHistoryCollectionRef = collection(
                  db,
                  "SupplyHistory"
                );
                const supplyHistoryDocRef = doc(supplyHistoryCollectionRef);
                const stationRef = doc(db, "AccessCodes", accessCode);
                const batch = writeBatch(db);

                batch.set(supplyHistoryDocRef, {
                  accessCode: accessCode,
                  timestamp: serverTimestamp(),
                  addedSupply: {
                    PHP5: addPHP5,
                    PHP10: addPHP10,
                    PHP20: addPHP20,
                  },
                });

                batch.update(stationRef, {
                  "currentVoucherSupply.PHP5": increment(addPHP5),
                  "currentVoucherSupply.PHP10": increment(addPHP10),
                  "currentVoucherSupply.PHP20": increment(addPHP20),
                });

                // april 25
                // find out how to increment this properly
                // if needs to use the old voucher supply, then use it
                batch.update(stationRef, {
                  currentVoucherSupply: {
                    PHP5: increment(addPHP5),
                    PHP10: increment(addPHP10),
                    PHP20: increment(addPHP20),
                  },
                });

                await batch.commit();
                navigate("/masterdashboard");
              } catch (error) {
                console.log(error);
                setError(true);
                setAddPHP5(null);
                setAddPHP10(null);
                setAddPHP20(null);
              }
            }}
            style={{
              width: "100%",
              backgroundColor: "#EB455F",
              maxWidth: 400,
              marginTop: 20,
              border: "none",
              borderRadius: 3,
              color: "#FCFFE7",
              fontWeight: "bold",
            }}
          >
            <p style={{ margin: 10 }}>Add Supply</p>
          </button>
        </div>
      </div>
    );
  }
};

export default MasterAddSupply;
