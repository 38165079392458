import React, { useState, useEffect, useRef } from "react";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router";
import GoBack from "../components/GoBack";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
} from "firebase/firestore";
import { db } from "../firebase";
import LoadingScreen from "./LoadingScreen";

const MasterAddStation = () => {
  const navigate = useNavigate();
  const { userRole } = UserAuth();
  const [newStationName, setNewStationName] = useState(null);
  const [newStationLocation, setNewStationLocation] = useState(null);
  const [newStationInitialSupply, setNewStationInitialSupply] = useState(null);
  const [newStationElectricity, setNewStationElectricity] = useState(null);
  const [newStationShare, setNewStationShare] = useState(null);
  const [addingStationError, setAddingStationError] = useState(false);

  useEffect(() => {
    if (userRole !== "master") {
      navigate("/", { replace: true, state: {} });
    }
  }, [userRole]);

  async function manualAccessCodeAdd(
    newStationAccessCode,
    newStationName,
    newStationInitialSupply,
    newStationLocation,
    newStationElectricity
  ) {
    const accessCodeRef = doc(db, "AccessCodes", newStationAccessCode);
    await setDoc(accessCodeRef, {
      accessPoints: 0,
      electricity: newStationElectricity,
      errorCodes: [],
      name: newStationName,
      requestRemit: false,
      share: newStationShare,
      station: newStationLocation,
      supply: {
        PHP5: newStationInitialSupply,
        PHP10: newStationInitialSupply,
        PHP15: newStationInitialSupply,
      },
      totalSales: 0,
      totalShareSales: 0,
      unliCodes: [],
      userRole: "station",
    });
  }

  async function generateUniqueAccessCode() {
    function generateRandomAccessCode() {
      // Generate a random 6-digit number
      return Math.floor(100000 + Math.random() * 900000);
    }
    const subAdminAccessCode = "900129";
    const subAdminRef = doc(db, "AccessCodes", subAdminAccessCode);
    const subAdminSnapshot = await getDoc(subAdminRef);

    if (subAdminSnapshot.exists()) {
      const listOfAllAccessCodes = subAdminSnapshot.data().managedStations;

      let generatedCode;
      let isUnique = false;

      while (!isUnique) {
        generatedCode = generateRandomAccessCode();
        isUnique = !listOfAllAccessCodes.includes(generatedCode.toString());
      }

      return generatedCode.toString();
    } else {
      throw new Error("Sub-admin access code does not exist.");
    }
  }

  async function addStationToSubAdmin(newStationData) {
    const subAdminAccessCode = "900129";
    const subAdminRef = doc(db, "AccessCodes", subAdminAccessCode);
    const subAdminSnapshot = await getDoc(subAdminRef);

    if (subAdminSnapshot.exists()) {
      const subAdminData = subAdminSnapshot.data();
      const managedStations = subAdminData.managedStations || [];

      // Add the new station data to the managedStations array
      managedStations.push(newStationData);

      // Update the subadmin document with the modified managedStations
      await updateDoc(subAdminRef, { managedStations });

      return "Station added successfully to the subadmin.";
    } else {
      throw new Error("Sub-admin access code does not exist.");
    }
  }

  return (
    <div
      style={{
        backgroundColor: "#083780",
        color: "white",
        margin: "0 auto",
        padding: "20px",
        overflowX: "hidden",
        width: 300,
      }}
    >
      <GoBack
        onClickFunction={() => {
          navigate("/masterdashboard", { replace: true });
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2 style={{ margin: 0, marginBottom: 10 }}>Add new station</h2>

        <div
          style={{
            marginTop: 10,
            marginBottom: 10,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p>Enter name of the station</p>
          <input
            type="text"
            value={newStationName}
            onChange={(e) => {
              const inputValue = e.target.value;
              // Check if the input contains at least one non-space character
              setNewStationName(inputValue);
            }}
            maxLength={20}
            placeholder={"Station Name"}
            style={{
              textAlign: "center",
              padding: 10,
              fontSize: 15,
              border: "none",
              borderRadius: 10,
              marginTop: 5,
            }}
          />
        </div>

        <div
          style={{
            marginTop: 10,
            marginBottom: 10,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p>Enter initial supply of the station</p>
          <input
            type="number"
            value={newStationInitialSupply}
            onChange={(e) => {
              const inputValue = e.target.value;
              setNewStationInitialSupply(parseInt(inputValue));
            }}
            maxLength={8}
            placeholder={"Initial Supply"}
            style={{
              textAlign: "center",
              padding: 10,
              fontSize: 15,
              border: "none",
              borderRadius: 10,
              marginTop: 5,
            }}
          />
        </div>

        <div
          style={{
            marginTop: 10,
            marginBottom: 10,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p>Enter station location</p>
          <input
            type="text"
            value={newStationLocation}
            onChange={(e) => {
              const inputValue = e.target.value;
              setNewStationLocation(inputValue);
            }}
            maxLength={20}
            placeholder={"Initial Location"}
            style={{
              textAlign: "center",
              padding: 10,
              fontSize: 15,
              border: "none",
              borderRadius: 10,
              marginTop: 5,
            }}
          />
        </div>

        <div
          style={{
            marginTop: 10,
            marginBottom: 10,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p>Enter station electricity</p>
          <input
            type="number"
            value={newStationElectricity}
            onChange={(e) => {
              const inputValue = e.target.value;
              setNewStationElectricity(parseInt(inputValue));
            }}
            maxLength={8}
            placeholder={"Electricity"}
            style={{
              textAlign: "center",
              padding: 10,
              fontSize: 15,
              border: "none",
              borderRadius: 10,
              marginTop: 5,
            }}
          />
        </div>

        <div
          style={{
            marginTop: 10,
            marginBottom: 10,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p>Enter station share</p>
          <input
            type="number"
            value={newStationShare}
            onChange={(e) => {
              const inputValue = e.target.value;
              setNewStationShare(parseFloat(inputValue));
            }}
            maxLength={8}
            placeholder={"Share (eg. 0.2)"}
            style={{
              textAlign: "center",
              padding: 10,
              fontSize: 15,
              border: "none",
              borderRadius: 10,
              marginTop: 5,
            }}
          />
        </div>

        <button
          onClick={async () => {
            try {
              if (
                newStationName &&
                newStationInitialSupply &&
                newStationLocation &&
                newStationElectricity &&
                newStationShare
              ) {
                const newStationAccessCode = await generateUniqueAccessCode();
                console.log(
                  newStationAccessCode,
                  newStationName,
                  newStationInitialSupply,
                  newStationLocation,
                  newStationElectricity
                );
                await manualAccessCodeAdd(
                  newStationAccessCode,
                  newStationName,
                  newStationInitialSupply,
                  newStationLocation,
                  newStationElectricity
                );
                await addStationToSubAdmin({
                  accessCode: newStationAccessCode,
                  name: newStationName,
                  station: newStationLocation,
                });
                navigate("/mastersuccessfuladdstation", {
                  state: newStationAccessCode,
                  replace: true,
                });
              } else {
                setAddingStationError(true);
              }
            } catch (error) {
              setAddingStationError(true);
              console.log(error);
            }
          }}
          style={{
            width: "100%",
            backgroundColor: "#EB455F",
            maxWidth: 400,
            marginTop: 20,
            border: "none",
            borderRadius: 3,
            color: "white",
            fontWeight: "bold",
          }}
        >
          <p style={{ margin: 10 }}>Add Station</p>
        </button>
        {addingStationError ? (
          <p style={{ fontSize: 12, color: "#EB455F", marginTop: 5 }}>
            An error occured while adding a new station
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default MasterAddStation;
