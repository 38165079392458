import React, { useState, useEffect, useRef } from "react";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
} from "firebase/firestore";
import { db } from "../firebase";
import LoadingScreen from "./LoadingScreen";

const MasterDashboard = () => {
  const navigate = useNavigate();
  const { accessCode, userRole } = UserAuth();
  const [DATA, setDATA] = useState(null);

  useEffect(() => {
    if (userRole !== "master") {
      navigate("/", { replace: true, state: {} });
    }
  }, [userRole]);

  //   useEffect(() => {
  //     const docRef = doc(db, "AccessCodes", accessCode);
  //     const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
  //       const data = docSnapshot.data();
  //       setDATA(data);
  //     });

  //     return () => {
  //       if (unsubscribe) {
  //         unsubscribe();
  //       }
  //     };
  //   }, [accessCode]);

  //   useEffect(() => {
  //     const docRef = doc(db, "Notifications", "SupplyRequests");
  //     const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
  //       const data = docSnapshot.data();
  //       setNotificationDATA(data);
  //     });

  //     return () => {
  //       if (unsubscribe) {
  //         unsubscribe();
  //       }
  //     };
  //   }, [userRole]);

  const EachStationButton = (props) => {
    return (
      <div style={{ display: "flex" }}>
        <button
          style={{ maxWidth: 150, padding: 5, marginTop: 5 }}
          onClick={() => {
            console.log(props);
          }}
        >
          <p style={{ fontWeight: "bold" }}>{props.stationName}</p>
        </button>
        {props.stationName === "Casantolan" ? (
          <button
            style={{
              color: "white",
              marginTop: 10,
              flex: 1,
              width: "100%",
              marginLeft: 2.5,
              marginRight: 2.5,
              backgroundColor: "#EB455F",
              border: "none",
              borderRadius: 5,
              padding: 10,
              maxWidth: 100,
              fontWeight: "bold",
            }}
            onClick={() => {
              navigate("/masteraddsupply", { state: "134149" });
            }}
          >
            <p style={{ fontWeight: "bold" }}>Add Supply</p>
          </button>
        ) : null}
      </div>
    );
  };

  const stationNames = ["Casantolan", "Valles", "Bono-anon"];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          padding: 20,
          width: 300,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h3>Welcome back, Master</h3>
          <p style={{}}>Stations</p>
          {/* this is for adding supply for major stations like valles, bono-anon and zumarraga and casantolan */}
          {/* {stationNames.map((station, index) => (
            <EachStationButton key={index} stationName={station} />
          ))} */}
          <button
            style={{
              marginTop: 20,
              width: "100%",
              backgroundColor: "white",
              maxWidth: 400,
              border: "none",
              borderRadius: 3,
              color: "black",
              fontWeight: "bold",
            }}
            onClick={() => {
              navigate("/masteraddstation");
            }}
          >
            <p style={{ margin: 10 }}>ADD NEW STATION</p>
          </button>
        </div>
        <div style={{ marginTop: 20, fontSize: 13 }}>
          <p style={{ fontWeight: "bold", fontSize: 15 }}>Remit Reports</p>
          <p style={{}}>Year: 2024</p>
        </div>
      </div>
    </div>
  );
};

export default MasterDashboard;
