import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import GoBack from "../components/GoBack";
import { db } from "../firebase";
import LoadingScreen from "./LoadingScreen";
import { collection, getDocs, where, query, orderBy } from "firebase/firestore";

const ViewRemitDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const docID = location.state.docID;
  const docDATA = location.state.docDATA;
  const [isLoading, setIsLoading] = useState(false);

  const remitDATA = docDATA.remitDATA;
  const stationDATA = docDATA.stationDATA;
  const timestamp = docDATA.timestamp;

  const supply = remitDATA.supply.old;
  const errorVouchers = remitDATA.voucher.error;
  const finalSupply = {
    PHP5: parseInt(supply.PHP5) - parseInt(errorVouchers.PHP5),
    PHP10: parseInt(supply.PHP10) - parseInt(errorVouchers.PHP10),
    PHP15: parseInt(supply.PHP15) - parseInt(errorVouchers.PHP15),
  };
  const remaining = remitDATA.voucher.remaining;
  const sold = {
    PHP5: parseInt(finalSupply.PHP5) - parseInt(remaining.PHP5),
    PHP10: parseInt(finalSupply.PHP10) - parseInt(remaining.PHP10),
    PHP15: parseInt(finalSupply.PHP15) - parseInt(remaining.PHP15),
  };
  const profit = {
    PHP5: parseInt(sold.PHP5) * 5,
    PHP10: parseInt(sold.PHP10) * 10,
    PHP15: parseInt(sold.PHP15) * 15,
  };
  const totalProfit = remitDATA.profit.total;
  const electricity = remitDATA.electricity
    ? stationDATA.electricityBenefit
    : 0;
  const sharing = {
    myShare: remitDATA.profit.myShare,
    stationShare: remitDATA.profit.stationShare,
  };
  const textCalculation = {
    withElectricity: {
      myShare:
        remitDATA.profit.total +
        " x " +
        (1 - stationDATA.percentageShare) * 100 +
        "% - " +
        stationDATA.electricityBenefit +
        " = " +
        sharing.myShare,
      stationShare:
        remitDATA.profit.total +
        " x " +
        stationDATA.percentageShare * 100 +
        "% + " +
        stationDATA.electricityBenefit +
        " = " +
        sharing.stationShare,
    },
    withoutElectricity: {
      myShare:
        remitDATA.profit.total +
        " x " +
        (1 - stationDATA.percentageShare) * 100 +
        "% = " +
        sharing.myShare,
      stationShare:
        remitDATA.profit.total +
        " x " +
        stationDATA.percentageShare * 100 +
        "% = " +
        sharing.stationShare,
    },
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div
      style={{
        backgroundColor: "#083780",
        color: "white",
        margin: "0 auto",
        padding: "20px",
        overflowX: "hidden",
        width: 300,
      }}
    >
      <GoBack
        onClickFunction={() => {
          navigate(-1);
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            color: "black",
            padding: 15,
            borderRadius: 20,
            width: 250,
          }}
        >
          <p
            style={{
              fontSize: 13,
              textAlign: "center",
              marginBottom: 10,
              color: "gray",
            }}
          >
            {formatDate(timestamp)}
          </p>
          <h4
            style={{
              textAlign: "center",
              margin: 0,
              fontWeight: "500",
            }}
          >
            Total Sales
          </h4>
          <h3
            style={{
              textAlign: "center",
              margin: 0,
            }}
          >
            PHP {remitDATA.profit.total}.00
          </h3>
          <div style={{ display: "flex", flex: 1, marginTop: 10 }}>
            <div style={{ alignItems: "center", flex: 1 }}>
              <p style={{ textAlign: "center", margin: 0, fontSize: 12 }}>
                Owner's Share
              </p>
              <p
                style={{
                  margin: 0,
                  textAlign: "center",
                  fontSize: 15,
                  fontWeight: "600",
                }}
              >
                PHP {remitDATA.profit.myShare}.00
              </p>
            </div>
            <div style={{ alignItems: "center", flex: 1 }}>
              <p style={{ textAlign: "center", margin: 0, fontSize: 13 }}>
                Station's Share
              </p>
              <p
                style={{
                  fontSize: 15,
                  margin: 0,
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                PHP {remitDATA.profit.stationShare}.00
              </p>
            </div>
          </div>
        </div>
        <h3 style={{ margin: 0, marginBottom: 10, marginTop: 10 }}>
          More Details
        </h3>
        <div style={{ fontSize: 13 }}>
          <h3 style={{ margin: 0 }}>Supply</h3>
          <p>5 PHP: {supply.PHP5}</p>
          <p>10 PHP: {supply.PHP10}</p>
          <p>15 PHP: {supply.PHP15}</p>
          <h3 style={{ margin: 0, marginTop: 10 }}>Error code</h3>
          <p>5 PHP: {errorVouchers.PHP5}</p>
          <p>10 PHP: {errorVouchers.PHP10}</p>
          <p>15 PHP: {errorVouchers.PHP15}</p>
          <p style={{ fontSize: 13, marginTop: 10 }}>
            <strong style={{ fontSize: 15 }}>Final Supply</strong> (Supply -
            Error code)
          </p>
          <p>
            5 PHP : {supply.PHP5} - {errorVouchers.PHP5} = {finalSupply.PHP5}
          </p>
          <p>
            10 PHP: {supply.PHP10} - {errorVouchers.PHP10} = {finalSupply.PHP10}
          </p>
          <p>
            15 PHP: {supply.PHP15} - {errorVouchers.PHP15} = {finalSupply.PHP15}
          </p>
          <h3 style={{ margin: 0, marginTop: 10 }}>Remaining</h3>
          <p>5 PHP: {remaining.PHP5}</p>
          <p>10 PHP: {remaining.PHP10}</p>
          <p>15 PHP: {remaining.PHP15}</p>
          <p style={{ fontSize: 13, marginTop: 10 }}>
            <strong style={{ fontSize: 15 }}>Sold</strong> (Final supply -
            Remaining)
          </p>
          <p>
            5 PHP: {finalSupply.PHP5} - {remaining.PHP5} = {sold.PHP5}
          </p>
          <p>
            10 PHP: {finalSupply.PHP10} - {remaining.PHP10} = {sold.PHP10}
          </p>
          <p>
            15 PHP: {finalSupply.PHP15} - {remaining.PHP15} = {sold.PHP15}
          </p>
          <p style={{ fontSize: 13, marginTop: 10 }}>
            <strong style={{ fontSize: 15 }}>Profit</strong> (Sold x Price)
          </p>
          <p>
            5 PHP: {sold.PHP5} x 5 = {profit.PHP5}
          </p>
          <p>
            10 PHP: {sold.PHP10} x 10 = {profit.PHP10}
          </p>
          <p>
            15 PHP: {sold.PHP15} x 15 = {profit.PHP15}
          </p>
          <h3 style={{ margin: 0, marginTop: 10 }}>Total profit</h3>
          <p>
            {profit.PHP5} + {profit.PHP10} + {profit.PHP15} = {totalProfit}
          </p>
          <h3 style={{ margin: 0, marginTop: 10 }}>Electricity</h3>
          <p
            style={{
              color: remitDATA.includeElectricity ? "white" : "gray",
            }}
          >
            {remitDATA.includeElectricity
              ? stationDATA.electricityBenefit
              : "Not yet"}
          </p>
          <h3 style={{ margin: 0, marginTop: 10 }}>Sharing </h3>
          <p>
            My Share:{" "}
            {remitDATA.includeElectricity
              ? textCalculation.withElectricity.myShare
              : textCalculation.withoutElectricity.myShare}
          </p>
          <p>
            Station Share:{" "}
            {remitDATA.includeElectricity
              ? textCalculation.withElectricity.stationShare
              : textCalculation.withoutElectricity.stationShare}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ViewRemitDetails;
