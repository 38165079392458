import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import SignIn from "./pages/SignIn";
import Protected from "./components/Protected";
import Dashboard from "./pages/Dashboard";
import RequestSupply from "./pages/RequestSupply";
import RequestRemit from "./pages/RequestRemit";
import ViewSupplyRequests from "./pages/ViewSupplyRequests";
import ViewUnliCodes from "./pages/ViewUnliCodes";
import ViewStation from "./pages/ViewStation";
import Remit from "./pages/Remit";
import ConfirmRemit from "./pages/ConfirmRemit";
import IncludeElectricity from "./pages/IncludeElectricity";
import AddErrorCode from "./pages/AddErrorCode";
import ViewErrorCodes from "./pages/ViewErrorCodes";
import DoneRemit from "./pages/DoneRemit";
import ViewSupplyHistory from "./pages/ViewSupplyHistory";
import ViewRemitHistory from "./pages/ViewRemitHistory";
import ViewRemitDetails from "./pages/ViewRemitDetails";
import MasterDashboard from "./pages/MasterDashboard";
import MasterAddStation from "./pages/MasterAddStation";
import TransactionHistory from "./pages/ViewTransactionHistory";
import ViewTransactionHistory from "./pages/ViewTransactionHistory";
import RemitAllStations from "./pages/RemitAllStations";
import ViewSubAdminRemitHistory from "./pages/ViewSubAdminRemitHistory";
import ViewSubAdminSupplyHistory from "./pages/ViewSubAdminSupplyHistory";
import MasterAddSupply from "./pages/MasterAddSupply";
import MasterSuccessfulAddStation from "./pages/MasterSuccessfulAddStation";

function App() {
  return (
    <div>
      <AuthContextProvider>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route
            path="/dashboard"
            element={
              <Protected>
                <Dashboard />
              </Protected>
            }
          />
          <Route
            path="/requestsupply"
            element={
              <Protected>
                <RequestSupply />
              </Protected>
            }
          />
          <Route
            path="/requestremit"
            element={
              <Protected>
                <RequestRemit />
              </Protected>
            }
          />
          <Route
            path="/viewsupplyrequests"
            element={
              <Protected>
                <ViewSupplyRequests />
              </Protected>
            }
          />
          <Route
            path="/viewunlicodes"
            element={
              <Protected>
                <ViewUnliCodes />
              </Protected>
            }
          />
          <Route
            path="/viewstation"
            element={
              <Protected>
                <ViewStation />
              </Protected>
            }
          />
          <Route
            path="/remit"
            element={
              <Protected>
                <Remit />
              </Protected>
            }
          />
          <Route
            path="/confirmremit"
            element={
              <Protected>
                <ConfirmRemit />
              </Protected>
            }
          />
          <Route
            path="/includeelectricity"
            element={
              <Protected>
                <IncludeElectricity />
              </Protected>
            }
          />
          <Route
            path="/adderrorcode"
            element={
              <Protected>
                <AddErrorCode />
              </Protected>
            }
          />
          <Route
            path="/viewerrorcodes"
            element={
              <Protected>
                <ViewErrorCodes />
              </Protected>
            }
          />
          <Route
            path="/doneremit"
            element={
              <Protected>
                <DoneRemit />
              </Protected>
            }
          />
          <Route
            path="/viewsupplyhistory"
            element={
              <Protected>
                <ViewSupplyHistory />
              </Protected>
            }
          />
          <Route
            path="/viewremithistory"
            element={
              <Protected>
                <ViewRemitHistory />
              </Protected>
            }
          />
          <Route
            path="/viewremitdetails"
            element={
              <Protected>
                <ViewRemitDetails />
              </Protected>
            }
          />
          <Route
            path="/masterdashboard"
            element={
              <Protected>
                <MasterDashboard />
              </Protected>
            }
          />
          <Route
            path="/masteraddstation"
            element={
              <Protected>
                <MasterAddStation />
              </Protected>
            }
          />
          <Route
            path="/masteraddsupply"
            element={
              <Protected>
                <MasterAddSupply />
              </Protected>
            }
          />
          <Route
            path="/mastersuccessfuladdstation"
            element={
              <Protected>
                <MasterSuccessfulAddStation />
              </Protected>
            }
          />
          <Route
            path="/viewtransactionhistory"
            element={
              <Protected>
                <ViewTransactionHistory />
              </Protected>
            }
          />
          <Route
            path="/remitallstations"
            element={
              <Protected>
                <RemitAllStations />
              </Protected>
            }
          />
          <Route
            path="/viewsubadminremithistory"
            element={
              <Protected>
                <ViewSubAdminRemitHistory />
              </Protected>
            }
          />
          <Route
            path="/viewsubadminsupplyhistory"
            element={
              <Protected>
                <ViewSubAdminSupplyHistory />
              </Protected>
            }
          />
        </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default App;
