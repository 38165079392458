import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const RequestRemit = () => {
  const navigate = useNavigate();
  const [remitDate, setRemitDate] = useState(new Date());

  return (
    <div>
      <button
        onClick={() => {
          navigate("../dashboard", { replace: true });
        }}
      >
        <p>Go back</p>
      </button>
      <h2>Request Remit</h2>
      <p>Send a request to remit today</p>
      <button>
        <p>Request Remit Today</p>
      </button>
    </div>
  );
};

export default RequestRemit;
