import React, { useState, useEffect } from "react";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
} from "firebase/firestore";
import { db } from "../firebase";
import ClipLoader from "react-spinners/ClipLoader";

const SignIn = () => {
  const {
    user,
    anonymousSignIn,
    setIsAccessCodeValid,
    accessCode,
    setAccessCode,
    setUserRole,
  } = UserAuth();
  const navigate = useNavigate();
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const masterPassword = "090617";

  async function checkAccessCodeValidity(accessCode) {
    try {
      // insert loading
      setIsButtonLoading(true);
      const accessCodeDocRef = doc(db, "AccessCodes", accessCode);
      const accessCodeDocSnapshot = await getDoc(accessCodeDocRef);

      if (accessCodeDocSnapshot.exists()) {
        // cancel loading
        setAccessCode(accessCode);
        setUserRole(accessCodeDocSnapshot.data().userRole);
        setIsAccessCodeValid(true);
        navigate("../dashboard", { replace: true });
      } else {
        setIsButtonLoading(false);
        setIsCodeInvalid(true);
      }
      setIsButtonLoading(false);
    } catch (error) {
      setIsButtonLoading(false);
      console.error("Error checking access code:", error);
    }
  }

  return (
    <div
      style={{
        backgroundColor: "#083780",
        color: "white",
        margin: "0 auto",
        padding: "20px",
        overflowX: "hidden",
        width: 300,
      }}
    >
      <h1>Enter access code</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          maxWidth: "300px",
        }}
      >
        <input
          type="number"
          maxLength={6}
          placeholder="Access Code"
          onChange={(e) => {
            setAccessCode(e.target.value);
          }}
          value={accessCode}
          style={{
            margin: 0,
            marginRight: 5,
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 10,
            flex: 0.8,
            borderRadius: "5px",
            border: "1px solid #ccc",
            height: "40px",
          }}
        />
        <button
          onClick={async () => {
            if (user) {
              if (accessCode === masterPassword) {
                setUserRole("master");
                navigate("/masterdashboard");
              } else {
                await checkAccessCodeValidity(accessCode);
              }
            } else {
              if (accessCode === masterPassword) {
                console.log("Yes");
                await anonymousSignIn();
                setUserRole("master");
                navigate("/masterdashboard");
              } else {
                await anonymousSignIn();
                await checkAccessCodeValidity(accessCode);
              }
            }
          }}
          disabled={!accessCode}
          style={{
            flex: 0.2,
            backgroundColor: accessCode ? "#EB455F" : "#ccc",
            color: "white",
            border: "none",
            borderRadius: "5px",
            height: "40px",
            cursor: accessCode ? "pointer" : "not-allowed",
          }}
        >
          {isButtonLoading ? (
            <ClipLoader color="white" size={20} />
          ) : (
            <h3 style={{ margin: 0, padding: 0 }}>Enter</h3>
          )}
        </button>
      </div>
      {isCodeInvalid ? (
        <p style={{ fontSize: 12, color: "red", marginTop: 10 }}>
          Error! Access code is invalid. Please try again
        </p>
      ) : null}
    </div>
  );
};

export default SignIn;
